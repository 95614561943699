import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useUser } from '../provider/UserProvider';
import ProtectedRoute from './ProtectedRoute';
import AccessDenied from "../pages/AccessDenied";
import {Login, SignUp} from "../pages";
import {clientRoutes} from "./ClientRoutes";

const Routers = () => {
  const { userData } = useUser();
  return (
    <Routes>
      { clientRoutes?.map((route) =>
        <Route
          key={ route.path }
          path={ route.path }
          element={ <ProtectedRoute isAllowed={ !!userData?.role }>{ route.element }</ProtectedRoute> } />
      ) }
      <Route path="/login" element={<Login />} />
      <Route path="/signUp" element={<SignUp />} />
      <Route path="/accessDenied" element={ <ProtectedRoute isAllowed={ !!userData?.role }><AccessDenied /></ProtectedRoute> } />
      <Route path="*" element={<ProtectedRoute isAllowed={ !!userData?.role }><AccessDenied /></ProtectedRoute>} />
    </Routes>
  )
}

export default Routers;
