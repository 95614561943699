import React, {useEffect, useState} from 'react';
import AddNewTicketModel from "../AddNewTicketModel";
import {useUser} from "../../hooks/useUser";
import {enqueueSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import Loader from "../../components/Loader/Loader";

interface CreateTicketRequestProps {
    approverIdFld: string
    jobDetails: string;
    jobDuration: any
    remarks: string,
    ticketTypeFld: any
    wareHouseIdFld: any,
    jobAttachmentsFld: any
}

const NewTickets = () => {
    const {  newTickets, useUserLoading } = useUser();
    const {getDeviceDropDown, deviceDropDownList} = useUser();
    const [open, setOpen] = useState<boolean>(true);
    const navigate = useNavigate();
    const {getAllWareHouse, wareHouseList} = useUser();
    const [startDate, setStartDate] = useState(new Date());
    const [selectedFile, setSelectedFile] = useState(null);


    useEffect(() => {
      getAllWareHouse();
      getDeviceDropDown('OPSTICKETTYPE')
    }, []);

    const handleFileChange = (event: { target: { files: any[]; }; }) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };



    const [value, setValue, ] = useState<CreateTicketRequestProps>({
        jobAttachmentsFld: '',
        approverIdFld: '',
        jobDetails: '',
        jobDuration: 5,
        remarks: '',
        ticketTypeFld: '',
        wareHouseIdFld: ''

    });

    const onChangeJobDetailsFld = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            jobDetails: event.target.value,
        });
    };

    const onChangeWareHouse = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            wareHouseIdFld: event.target.value,
        });
    };

    const onChangeDuration = (val: any) => {
        setValue({
            ...value,
            jobDuration: val,
        });
    };


    const onChangeTicketTypeFld = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            ticketTypeFld: event.target.value,
        });
    };

    const onChangejobAttachmentsFld = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            jobAttachmentsFld: event.target.value,
        });
    };
    const onChangeRemarksFld = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            remarks: event.target.value,
        });
    };
    const onChangeApproverRemarksFld = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            approverIdFld: event.target.value,
        });
    };

    const onChangeJobDateFld = (event: Date | string | null) => {
        setValue({
            ...value,
            jobDuration: event,
        });
    };

    const onChangeJobDurationFld = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            jobDuration: event.target.value,
        });
    };


    const onCloseModal = () => {
        setOpen(false);
        navigate('/' );
    };

    const onClickConfirm = async () => {
        if(value.jobDetails === ''){
            enqueueSnackbar("Please enter mandatory field", { variant: 'error',autoHideDuration: 3000 } )
            return true;
        } else  {
            try {
                await newTickets({
                    approverIdFld: value.approverIdFld,
                    jobDetails: value.jobDetails,
                    jobDuration: value.jobDuration,
                    remarks: value.remarks,
                    ticketTypeFld: value.ticketTypeFld,
                    wareHouseIdFld: value.wareHouseIdFld
                }).then(r => {
                    console.log(r);
                    if(r?.response && r?.response?.status === 400){
                        enqueueSnackbar(r?.response?.data, { variant: 'error',autoHideDuration: 3000 } )
                    }else {
                        enqueueSnackbar(r.message, { variant: 'success',autoHideDuration: 3000 } )
                        window.history.back()
                    }
                });
                setOpen(false);
            } catch (e : any) {
                console.log(e)
                setOpen(false);
            }
        }
    };

    return (
        <div>
            {useUserLoading ? <Loader></Loader> : <div>
                <AddNewTicketModel open={open}
                                   value={value}
                                   onClickPrimary={onClickConfirm}
                                   handleClose={onCloseModal}
                                   onChangeJobDetailsFld={onChangeJobDetailsFld}
                                   onChangeRemarksFld={onChangeRemarksFld}
                                   onChangeApproverRemarksFld={onChangeApproverRemarksFld}
                                   onChangeWarehouse={onChangeWareHouse}
                                   ticketTypeList={deviceDropDownList}
                                   wareHouseList={wareHouseList}
                                   onChangeTicketTypeFld={onChangeTicketTypeFld}
                                   onChangeJobDateFld={onChangeJobDateFld}
                                   onChange={onChangeJobDateFld}
                                   setStartDate={setStartDate}
                                   startDate={startDate}
                                   onChangeJobDurationFld={onChangeJobDurationFld}
                                   handleFileChange={handleFileChange}
                                   selectedFile={selectedFile}
                                   setSelectedFile={setSelectedFile}
                                   onChangeDuration={onChangeDuration}
                                   onChangejobAttachmentsFld={onChangejobAttachmentsFld}></AddNewTicketModel>
            </div>}


        </div>

    )
}

export default NewTickets;
