import React, {useEffect} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment-timezone";
import {useDropdown} from "../../hooks/useDropdown";
import FromDropdown from "../FormDropdown/FormDropdown";

const style = {
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    height: '80vh'
};

interface FromModalProps {
    open: boolean;
    readOnly: boolean;
    buttonTitle: string;
    title: string;
    onChange: (data: string, metaId: string) => void;
    onClickPrimary: () => void;
    handleClose: () => void;
    meta: any;
    data: any
}

const renderChild =  (m: any, data: any, onChange: any, readOnly: boolean, getListForDropdown: any) => {

    let value = data[m.name];
    if (m.type === "INPUT") {
        return (<TextField disabled={readOnly} placeholder={m.placeholder} fullWidth={true} variant="outlined"
                           label={m.placeholder} name={m.name} value={value}
                           onChange={(e) => onChange(e.target.value, m.name)}/>)
    } else if (m.type === "DATE") {
        return <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                sx={{width: '100%'}}
                disablePast={true}
                label={m.placeholder}
                value={value ? new Date(value) : Date.now()}
                onChange={(e: any) => {
                    const parsedDate = moment(e, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Z)");
                    const milliseconds = parsedDate.valueOf();
                    console.log(milliseconds);
                    onChange(milliseconds, m.name)
                }}
                // viewRenderers={(params:any) => <TextField placeholder={m.placeholder} fullWidth={true} variant="outlined" label={m.placeholder} name={m.name} value={ value } onChange={ (e) => onChange(e.target.value, m.name) } />}
            /></LocalizationProvider>
    } else if (m.type === "DROPDOWN") {
        return (<FromDropdown
                    onChange={onChange}
                    name={m.name}
                    placeHolder={m.placeholder}
                    selectedValue={value}
                    source={m.source}/>)

    } else if (m.type === "MULTIDROPDOWN") {
        let dropdownList:any[] =  [];

        return <FormControl style={{width: '100%'}}>
            <InputLabel id="demo-simple-select-label">{m.placeholder}</InputLabel>
            <Select
                multiple={true}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value ?? []}
                label="m.placeHolder"
                onChange={(e: any) => {
                    let selectedValues = value ?? [];
                    console.log(e.target.value)
                    onChange(e.target.value, m.name);
                }}
            >
                {dropdownList && dropdownList.map((m: any) => {
                    return <MenuItem value={m.id}>{m.name}</MenuItem>
                })}
            </Select>
        </FormControl>
    } else if (m.type === "AMOUNT") {
        return (<TextField type={'number'} placeholder={m.placeholder} fullWidth={true} variant="outlined"
                           label={m.placeholder} name={m.name} value={value}
                           onChange={(e) => onChange(e.target.value, m.name)}/>)

    }
    return (<div></div>)
}
const FromModal = ({ open, buttonTitle, title, onChange, onClickPrimary, handleClose, meta, data, readOnly }: FromModalProps) => {

    const { getListForDropdown } = useDropdown();

    useEffect(()=> {
        // fetchSkillsList();
        // const clientId = localStorage.getItem(STORAGE_KEY.UserId);
        // getClientDetails({clientId : clientId ?? ''});
        }, [])

    return (

        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                { title }
            </Typography>
            <Grid container spacing={4} paddingTop={3}>
                {meta.map( (m: any) => {
                    return <Grid key={m.name} item xs={6} sm={6} lg={6} xl={6}>
                        { renderChild(m, data, onChange, readOnly, getListForDropdown)}
                    </Grid>
                })}
            </Grid>
            {readOnly ? <div/> : <Stack direction="row" spacing={ 4 } paddingTop={3}>
                <Button style={{backgroundColor : "#3361FF"}} variant="text"  fullWidth={true} onClick={ handleClose }>Cancel</Button>
                <Button variant="contained" style={{backgroundColor : "#3361FF"}} fullWidth={true} onClick={ onClickPrimary }>{ buttonTitle }</Button>
                </Stack>}

        </Box>
    );
};

export default FromModal;
