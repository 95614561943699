import React, {useEffect} from 'react';
import {metData} from "../../constants/meta";
import FromModal from "../../components/Form";
import {useLocation, useNavigate} from "react-router-dom";
import {useUser} from "../../hooks/useUser";
import Loader from "../../components/Loader/Loader";

const TicketDetail = () => {
    const location = useLocation();
    const {getTicketById, ticketDetail, useUserLoading} = useUser();
    const locationData: any = location?.state?.data || {};
    const navigate = useNavigate();

    useEffect(() => {
        if(locationData && locationData.data && locationData.data.oidPkFld != null){
            getTicketById( locationData.data.oidPkFld);
        } else {
            navigate('/', { replace: true });
        }
    }, []);

    return (
    <div>
        {useUserLoading ? <Loader></Loader> : <div>
            {
                ticketDetail ?
                    <FromModal buttonTitle={'Update'} title={'Ticket Details'}
                               onChange={() => {}}
                               readOnly={true}
                               data={ticketDetail}
                               meta={metData.newTickets}
                               handleClose={() => {}}
                               open={true}
                               onClickPrimary={() => {}}/> : <div></div>
            }
        </div> }

    </div>
  )
}

export default TicketDetail;


