import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import React from 'react';

const style = {
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    height: '80vh'
};

interface AddNewWareHouseModalProps {
    open: boolean;
    value: {
        warehouseNameFld: string;
        warehouseIdFld: string;
        branchIdFld: string;
        addressFld: string;
        custom1Fld: string,
        custom2Fld: string,
        custom3Fld: string,
        custom4Fld: string,
        custom5Fld: string,
        geoLocationFld: string,
        enabled: boolean;
        remarksFld: string

    }
    onClickPrimary: () => void;
    handleClose: () => void;
    branchList: any[];


    onChangeWarehouseNameFld: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeBranchIdFld: any;
    onChangeAddressFld: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeWarehouseIdFld: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeCustom1Fld: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeCustom2Fld: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeCustom3Fld: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeCustom4Fld: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeCustom5Fld: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeGeoLocationFld: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeRemarksFld: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const AddNewWareHouseModel = ({ open, value,  onClickPrimary, handleClose, onChangeWarehouseNameFld, onChangeWarehouseIdFld,onChangeBranchIdFld,onChangeAddressFld, onChangeCustom1Fld, onChangeCustom2Fld,onChangeCustom3Fld, onChangeCustom4Fld,onChangeCustom5Fld,onChangeRemarksFld,onChangeGeoLocationFld,branchList}: AddNewWareHouseModalProps) => {
    return (
        <div>
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{ textAlign: 'center' }}>
                        New WareHouse
                    </Typography>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', alignItems: 'center'}}>
                        {branchList != null ? (
                            <FormControl style={{ width: '30%', flexDirection: 'column' }}>
                                <InputLabel>Branch ID*</InputLabel>
                                <MenuItem style={{color: 'grey', flexDirection: 'column' }} value="" disabled>
                                </MenuItem>
                                <Select
                                    value={value.branchIdFld}
                                    onChange={(event: SelectChangeEvent<string>) => onChangeBranchIdFld(event)}
                                    displayEmpty
                                >
                                    {branchList.map((item: any) => (
                                        <MenuItem style={{color: 'grey', flexDirection: 'column' }} value={item.oidPkFld}>
                                            {item.branchNameFld}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        ) : <div></div>}

                        <TextField style={{ width: '30%',color: 'grey', }} multiline={false} minRows={"1"} placeholder="Station Id*" name="warehouseIdFld" value={ value.warehouseIdFld } onChange={ onChangeWarehouseIdFld } />
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', alignItems: 'center'}}>
                        <TextField style={{ width: '30%',color: 'grey', }} multiline={false} minRows={"1"} placeholder="Station Name*" name="warehouseNameFld" value={ value.warehouseNameFld } onChange={ onChangeWarehouseNameFld } />
                        <TextField style={{ width: '30%',color: 'grey', }} multiline={false} minRows={"1"} placeholder="Geolocation*" name="geoLocationFld" value={ value.geoLocationFld } onChange={ onChangeGeoLocationFld } />
                    </div>

                   <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', alignItems: 'center'}}>
                       <TextField style={{ width: '30%' ,color: 'grey',}} multiline={false} minRows={"1"} placeholder="Address*" name="AddressFld" value={ value.addressFld } onChange={ onChangeAddressFld } />
                       <TextField  style={{ width: '30%',color: 'grey', }} multiline={false} minRows={"1"} placeholder="Remarks" name="remarksFld" value={ value.remarksFld } onChange={ onChangeRemarksFld } />
                   </div>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', alignItems: 'center'}}>
                        <Stack direction="row" spacing={ 20 } >
                            <Button style={{ backgroundColor : "#3361FF"}}  variant="outlined" onClick={ handleClose }>Cancel</Button>
                            <Button style={{ backgroundColor : "#3361FF"}}  variant="contained" onClick={ onClickPrimary }>Save</Button>
                        </Stack>
                    </div>
                </Box>
        </div>
    )
}

export default AddNewWareHouseModel;
