import React, { useCallback, useEffect, useState } from "react";
import { useUser } from "../../hooks/useUser";
import Loader from "../../components/Loader/Loader";
import { Box, SelectChangeEvent } from "@mui/material";
import StationAudit from "../../components/StationAudit";
import RelayRoomStatusComponent from "../../components/RelayRoomStatusComponent";
import TabBar from "../../components/TabBar";
import RelayRoomDropdown from "../../components/RelayRoomDropdown";

interface RelayRoomStatusPageModelProps {
  branchIdFld?: string;
}

const RelayRoomStatus: React.FC = () => {
  const {
    getAllWareHouse,
    getRelayRoomStatus,
    getStationAudit,
    useUserLoading,
    wareHouseList,
    relayRoomStatus,
    stationAudit,
  } = useUser();

  useEffect(() => {
    getAllWareHouse();
  }, []);

  const [value, setValue] = useState<RelayRoomStatusPageModelProps>({
    branchIdFld: undefined,
  });

  const onChangeBranchEvent = useCallback(
    (event: SelectChangeEvent<string>) => {
      const newValue = event.target.value;
      setValue({ ...value, branchIdFld: newValue });
      if (newValue != null) {
        getRelayRoomStatus(parseInt(newValue));
        getStationAudit(parseInt(newValue));
      }
    },
    [value, getRelayRoomStatus, getStationAudit]
  );

  const [tabBarValue, setTabBarValue] = React.useState(0);

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setTabBarValue(newValue);
    },
    [setTabBarValue]
  );

  return (
    <Box display="flex" flexDirection="column">
      {useUserLoading ? (
        <Loader />
      ) : (
        <RelayRoomDropdown
          value={value}
          wareHouseList={wareHouseList}
          onChangeBranchIdFld={onChangeBranchEvent}
        />
      )}
      <TabBar handleChange={handleChange} value={tabBarValue} />
      <Box display="flex">
        {relayRoomStatus && tabBarValue === 0 && (
          <RelayRoomStatusComponent messageFld={relayRoomStatus.rrDoorStatus} />
        )}

        {stationAudit && tabBarValue === 1 && stationAudit.length > 0 && (
          <StationAudit stationAudit={stationAudit} />
        )}
      </Box>
    </Box>
  );
};

export default RelayRoomStatus;
