import PendingForApproval from "../pages/PendingForApproval";
import ApprovedTickets from "../pages/ApprovedTickets";
import UserList from "../pages/UserList";
import UserDetail from "../pages/UserDetail";
import MyDashBoard from "../pages/MyDashBoard";
import Device from "../pages/Device";
import Notifications from "../pages/Notifications";
import WareHouse from "../pages/WareHouse";
import WareHouseDetails from "../pages/WareHouseDetail";
import DeviceDetails from "../pages/DeviceDetail";
import OpenTickets from "../pages/OpenTickets";
import NewTickets from "../pages/NewTickets";
import PendingTickets from "../pages/PendingTickets";
import NewUser from "../pages/NewUser";
import NewDevice from "../pages/NewDevice";
import NewWareHouse from "../pages/NewWareHouse";
import TicketDetail from "../pages/TicketDetail";
import Profile from "../pages/Profile";
import ChangePassword from "../pages/ChangePassword";
import RelayRoomStatus from "pages/RelayRoomStatus";

interface ClientRoutesProps {
  path: string;
  element: JSX.Element;
}

export const clientRoutes: ClientRoutesProps[] = [
  { path: "/pendingApproval", element: <PendingForApproval /> },
  { path: "/approvedTickets", element: <ApprovedTickets /> },
  { path: "/user", element: <UserList /> },
  { path: "/user/:oidPkFld", element: <UserDetail /> },
  { path: "/", element: <MyDashBoard /> },
  { path: "/device", element: <Device /> },
  { path: "/device/:oidPkFld", element: <DeviceDetails /> },
  { path: "/notifications", element: <Notifications /> },
  { path: "/wareHouse", element: <WareHouse /> },
  { path: "/relayRoomStatus", element: <RelayRoomStatus /> },
  { path: "/wareHouse/:oidPkFld", element: <WareHouseDetails /> },
  { path: "/openTickets", element: <OpenTickets /> },
  { path: "/newTickets", element: <NewTickets /> },
  { path: "/newDevice", element: <NewDevice /> },
  { path: "/newWareHouse", element: <NewWareHouse /> },
  { path: "/newUser", element: <NewUser /> },
  { path: "/pendingTickets", element: <PendingTickets /> },
  { path: "/approvedTickets", element: <ApprovedTickets /> },
  { path: "/newTickets/:oidPkFld", element: <TicketDetail /> },
  { path: "/profile", element: <Profile /> },
  { path: "/changePassword/:oidPkFld", element: <ChangePassword /> },
];
