import React, { useEffect, useState } from "react";
import { useTable } from "react-table";
import "./Table.css";
import { Link } from "react-router-dom";
import { splitCamelCaseAndUppercase } from "../DetailView"; // Import the CSS file for styling
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import TextField from "@mui/material/TextField";
import { metData } from "../../constants/meta";
import { Add } from "@mui/icons-material";
import { theme } from "../../theme";
import { format } from "date-fns";
import noData from "../../assets/images/no-data.png";
import FromPopup from "../FormModel";

export interface TableProps {
  listData: { [key: string]: any }[];
  hyperlinkPropertyName: string;
  entity: any;
  enabledEdit?: boolean;
  canAdd?: boolean;
  onUpdate?: any;
  actionButtons?: any;
  hiddenFields?: any[];

  onClick?: any;
}

const Table: React.FC<TableProps> = ({
  listData,
  entity,
  hyperlinkPropertyName,
  enabledEdit,
  onUpdate,
  canAdd,
  actionButtons,
  hiddenFields,
  onClick,
}) => {
  const [data, setData] = useState<any | {}>([]);
  const [updateIndex, setUpdateIndex] = useState<any | 0>(0);
  const [updateData, setUpdateData] = useState<any | {}>({});
  const [updateMetaData, setUpdateMetaData] = useState<any | []>([]);
  const [metaData, setMetaData] = useState<any | []>([]);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (listData) {
      setData(listData);
      setMetaData(metData[entity] ?? []);
      setUpdateMetaData(metData[entity + "_rel"] ?? []);
    }
  }, [listData, hyperlinkPropertyName, entity]);

  const handleClose = () => {
    setOpen(!open);
  };

  const onNewRowAdd = async (entity: string) => {
    data[updateIndex] = updateData;
    onUpdate(updateData, updateIndex);
    setData([...data]);
    setUpdateIndex(0);
    setUpdateData([]);
    setOpen(false);
  };
  const onCreateFormChange = (data: any, metaId: string) => {
    console.log(updateData);
    updateData[metaId] = data;
    setUpdateData({ ...updateData });
  };

  const renderDate = (meta: any, row: any) => {
    const formattedDate = format(
      new Date(data[row.index][meta.name]),
      "MMMM d, yyyy"
    );
    return <span>{formattedDate}</span>;
  };

  const renderAmount = (meta: any, row: any) => {
    const formattedPrice = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(data[row.index][meta.name]);
    return <span>{formattedPrice}</span>;
  };

  const renderInput = (meta: any, row: any) => {
    return (
      <TextField
        label={meta.placeholder}
        value={data[row.index][meta.name]}
        onChange={(event) => {
          data[row.index][meta.name] = event.target.value;
          setData(
            data.map((v: any) => {
              return { ...v };
            })
          );
        }}
        InputProps={{ readOnly: meta.name === "id" }}
        variant="outlined"
      />
    );
  };

  const columns = React.useMemo(() => {
    const keys = data.reduce((acc: string[], obj: { [key: string]: any }) => {
      if (obj)
        Object.keys(obj)
          .filter(
            (k) => hiddenFields == null || hiddenFields?.indexOf(k) === -1
          )
          .forEach((key) => {
            if (
              !acc.includes(key) &&
              typeof obj[key] != "object" &&
              key !== "editMode"
            ) {
              acc.push(key);
            }
          });
      return acc;
    }, []);

    let visibleMeta = metaData.filter(
      (f: any) => keys.filter((m: any) => f.name === m && !f.hidden).length > 0
    );

    if (enabledEdit && keys.length > 0) {
      visibleMeta.push({ name: "Edit" });
    }
    return visibleMeta.map((meta: any) => ({
      Header: meta.placeholder, // Keep the original header name
      accessor: meta.name,
      Cell: ({
        value,
        row,
      }: {
        value: any;
        row: { original: { [key: string]: any }; index: any };
      }) => {
        if (data) {
          const canEdit = data[row.index] ? data[row.index]["editMode"] : false;
          const isNumeric =
            typeof value === "number" ||
            (!isNaN(parseFloat(value)) && isFinite(value));
          const isHyperlink = meta.name === hyperlinkPropertyName;
          if (isHyperlink) {
            let linkData = data.filter((fil: any) =>
              fil != null ? fil[hyperlinkPropertyName] === value : false
            );
            return (
              <Link
                to={{
                  pathname: "/" + entity + "/" + value,
                }}
                state={{
                  data: {
                    data: linkData.length > 0 ? linkData[0] : {},
                    actionButtons: actionButtons,
                  },
                }}
              >
                {value}
              </Link>
            );
          } else if (meta.type === "DATE") {
            return renderDate(meta, row);
          } else if (meta.type === "AMOUNT") {
            return renderAmount(meta, row);
          } else if (meta.name === "Edit") {
            return (
              <IconButton
                onClick={() => {
                  setUpdateIndex(row.index);
                  setUpdateData({ ...data[row.index] });
                  setOpen(true);
                }}
                color="primary"
                aria-label="edit"
                size="small"
                style={{
                  padding: "6px",
                  margin: "2px",
                  width: "5px",
                  height: "5px",
                  textAlign: "center",
                }}
              >
                <EditIcon style={{ fontSize: "18px" }} />
              </IconButton>
            );
          }
          if (canEdit) {
            return renderInput(meta, row);
          }
          return (
            <span style={isNumeric ? { textAlign: "center" } : {}}>
              {value}
            </span>
          );
        }
      },
    }));
  }, [data, data.length]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  if (data.length === 0 && !enabledEdit) {
    return (
      <div>
        <img
          src={noData}
          alt="nodata"
          style={{
            position: "fixed",
            height: "70vh",
            display: "flex",
            paddingLeft: "50vh",
            paddingRight: "50vh",
            paddingTop: "5vh",
          }}
        />
      </div>
    );
  }
  return (
    <div className="table-container">
      <table {...getTableProps()} className="table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              {headerGroup.headers.map((column: any) => (
                <th
                  {...column.getHeaderProps()}
                  key={column.id}
                  style={{ backgroundColor: "#F1F5F9" }}
                >
                  {column.Header
                    ? capitalizeAllWords(
                        splitCamelCaseAndUppercase(column.render("Header"))
                      )
                    : ""}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                key={row.id}
                onClick={() => (onClick ? onClick(row.id) : null)}
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} key={cell.column.id}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {enabledEdit ? (
        <div
          style={{ display: "flex", justifyContent: "flex-end", paddingTop: 5 }}
        >
          <IconButton
            onClick={() => {
              let row = data.length;
              setData(
                data.map((d: any) => {
                  return { ...d };
                })
              );
              setUpdateIndex(row);
              setUpdateData({ ...data[row] });
              setOpen(true);
            }}
            color="primary"
            aria-label="edit"
            size="medium"
            style={{
              border: `2px solid ${theme.palette.primary.main}`,
              padding: "6px",
              margin: "2px",
              width: "25px",
              height: "25px",
              textAlign: "center",
            }}
          >
            <Add style={{ fontSize: "25px" }} />
          </IconButton>
        </div>
      ) : (
        <div></div>
      )}
      <FromPopup
        buttonTitle={"Update"}
        title={entity}
        onChange={onCreateFormChange}
        readOnly={false}
        data={updateData}
        meta={updateMetaData}
        handleClose={handleClose}
        open={open}
        onClickPrimary={() => onNewRowAdd(entity)}
      />
    </div>
  );
};

export default Table;

// Helper function to capitalize the first letter of each word
function capitalizeAllWords(str: string): string {
  return str.toLowerCase().replace(/(^|\s)\S/g, (l) => l.toUpperCase());
}
