import React, { useEffect, useState } from "react";
import Table from "../../components/Table";
import { useUser } from "../../hooks/useUser";
import Loader from "../../components/Loader/Loader";
import { Box } from "@mui/material";
import PaginationComponent from "../../components/PaginationComponent";

const UserList = () => {
  const { getAllUser, userList, useUserLoading, userCount } = useUser();
  const [currentPage, setCurrentPage] = useState<number>(0);

  useEffect(() => {
    getAllUser(currentPage);
  }, []);

  const handlePageChange = async (
    _event: React.ChangeEvent<unknown>,
    pageNumber: number
  ) => {
    await getAllUser(pageNumber - 1);
    setCurrentPage(pageNumber);
  };

  return (
    <Box>
      {useUserLoading ? (
        <Loader />
      ) : (
        <Box>
          <h1>User List</h1>
          <Table
            listData={userList}
            hyperlinkPropertyName={"oidPkFld"}
            entity={"user"}
          />
          <Box>
            <PaginationComponent
              count={userCount}
              page={currentPage}
              handleChange={handlePageChange}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default UserList;
