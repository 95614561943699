import React, {useEffect, useState} from 'react';
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {useDropdown} from "../../hooks/useDropdown";

export type FormDropdownProps = {
    placeHolder: string;
    selectedValue: any;
    onChange: (any);
    source: string;
    name: string;

}

export default function FromDropdown({placeHolder,selectedValue,onChange, source,name}: FormDropdownProps) {
    console.log(name)

    const {getListForDropdown} = useDropdown();
    const [dropdownList, setDropDownList] = useState<any | []>([]);

    useEffect(  () => {
         getListForDropdown(source).then((data:any[]) => {
             setDropDownList([...data]);
         })
    }, [])

    return <FormControl fullWidth style={{width: '400px'}}>
        <InputLabel id="demo-simple-select-label">{placeHolder}</InputLabel>
        <Select

            fullWidth={true}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedValue}
            label="m.placeHolder"
            onChange={(e: any) => {
                onChange(e.target.value.name, name);
                onChange(e.target.value.id, "oidPkFld");
            }}
        >
            {dropdownList && dropdownList.map((m: any) => {
                return <MenuItem value={m}>{m.name}</MenuItem>
            })}
        </Select>
    </FormControl>;
}
