// MenuComponent.tsx
import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import "./MenuComponent.css";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Link, useNavigate } from "react-router-dom";
import {
  Devices,
  ExpandLess,
  ExpandMore,
  Warehouse,
  Group,
  BookOnlineTwoTone,
  Add,
  DashboardCustomize,
  Person,
  Logout,
  MeetingRoom,
} from "@mui/icons-material";
import {
  Collapse,
  IconButton,
  ListItemButton,
  ListSubheader,
  Typography,
} from "@mui/material";
import logo from "../../assets/images/wims.png";
import MenuItem from "@mui/material/MenuItem";
import { STORAGE_KEY } from "../../constants";
import { enqueueSnackbar } from "notistack";
interface MenuComponentProps {
  open: boolean;
  onClose: () => void;
}

const MenuComponent: React.FC<MenuComponentProps> = ({ open, onClose }) => {
  const [menuItems, setMenuItems]: any = React.useState([]);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleLogout = () => {
    handleClose();
    localStorage.removeItem(STORAGE_KEY.AuthToken);
    localStorage.removeItem(STORAGE_KEY.UserDetails);
    navigate("/login", { replace: true });
    enqueueSnackbar("Logout Successfully", {
      variant: "success",
      autoHideDuration: 3000,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setMenuItems([
      {
        label: "My DashBoard",
        icon: <DashboardCustomize />,
        linkTo: "/",
        subMenu: [],
        isOpen: false,
      },
      {
        label: "Users",
        icon: <Person />,
        linkTo: "/user",
        subMenu: [
          {
            label: "All",
            icon: <Group />,
            linkTo: "/user",
          },

          {
            label: "New",
            icon: <Add />,
            linkTo: "/newUser",
          },
        ],
        isOpen: false,
      },
      {
        label: "Station Config",
        icon: <Warehouse />,
        linkTo: "/wareHouse",
        subMenu: [
          {
            label: "All",
            icon: <Group />,
            linkTo: "/wareHouse",
          },

          {
            label: "New",
            icon: <Add />,
            linkTo: "/newWareHouse",
          },
        ],
        isOpen: false,
      },
      {
        label: "Device",
        icon: <Devices />,
        linkTo: "/device",
        subMenu: [
          {
            label: "All",
            icon: <Group />,
            linkTo: "/device",
          },

          {
            label: "New",
            icon: <Add />,
            linkTo: "/newDevice",
          },
        ],
        isOpen: false,
      },
      {
        label: "Tickets",
        icon: <BookOnlineTwoTone />,
        linkTo: "/openTickets",
        isOpen: false,
        subMenu: [
          {
            label: "New Tickets",
            linkTo: "/newTickets",
          },

          {
            label: "All Tickets",
            linkTo: "/pendingTickets",
          },
          {
            label: "Approved Tickets",
            linkTo: "/approvedTickets",
          },
        ],
      },
      {
        label: "Relay Room Status",
        icon: <MeetingRoom />,
        linkTo: "/relayRoomStatus",
        subMenu: [],
        isOpen: false,
      },
      // {
      //     label: 'Notifications',
      //     icon:  <NotificationsActive />,
      //     linkTo: "/notifications",
      //     subMenu: []
      // },

      // Add more menu items as needed
    ]);
  }, []);

  const handleClick = (index: number) => {
    menuItems[index].isOpen = !menuItems[index].isOpen;
    console.log(menuItems);
    setMenuItems([...menuItems]);
  };

  const handleMenuItemClick = (item: any) => {
    if (item.onClick) {
      console.log(item.onClick);
      item.onClick();
    }
    onClose(); // Close the menu after handling the click event
  };

  return (
    <List
      sx={{
        width: "100%",
        maxWidth: 360,
        bgcolor: "background.paper",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
      }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          <Typography sx={{ flexGrow: 1, textAlign: "center" }} variant="h6">
            <img
              style={{ margin: "5px", height: "30" }}
              src={logo}
              alt="Colobten"
              className="logo-img"
            />
          </Typography>
        </ListSubheader>
      }
    >
      {menuItems.map((item: any, index: number) => (
        <div>
          <ListItem onClick={() => handleClick(index)}>
            {item.subMenu.length > 0 ? (
              <ListItemButton style={{ color: "grey" }}>
                {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                <ListItemText
                  primary={item.label}
                  style={{ textDecoration: "Label", color: "grey" }}
                />
                {item.isOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            ) : (
              <ListItem
                to={item.linkTo || "#"}
                component={Link}
                onClick={() => handleMenuItemClick(menuItems)}
              >
                {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                <ListItemText
                  primary={item.label}
                  style={{ textDecoration: "Label", color: "grey" }}
                />
              </ListItem>
            )}
          </ListItem>
          {item.subMenu.length > 0 ? (
            <Collapse in={item.isOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {item.subMenu.map((subMenu: any, index: number) => (
                  <ListItem
                    sx={{ pl: 8 }}
                    key={index}
                    to={subMenu.linkTo || "#"}
                    component={Link}
                    onClick={() => handleMenuItemClick(subMenu.linkTo)}
                  >
                    <ListItemButton>
                      {subMenu.icon && (
                        <ListItemIcon>{subMenu.icon}</ListItemIcon>
                      )}
                      <ListItemText
                        primary={subMenu.label}
                        style={{ textDecoration: "Label", color: "grey" }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Collapse>
          ) : (
            <div />
          )}
        </div>
      ))}
      <MenuItem style={{ justifyContent: "start" }} onClick={handleLogout}>
        <IconButton style={{ color: "grey" }} sx={{ pl: 2 }}>
          <Logout fontSize="large" />
        </IconButton>
        <Typography style={{ color: "grey" }} sx={{ pl: 2 }}>
          Logout
        </Typography>
      </MenuItem>
    </List>
  );
};

export default MenuComponent;
