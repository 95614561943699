import {Box} from '@mui/material';
import React, {useEffect, useState} from "react";
import LoginLayout from "../../layouts/LoginLayout";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import {STORAGE_KEY} from "../../constants";
import {useUser} from "../../hooks/useUser";


const Login = () => {
    const navigate = useNavigate();
    const {userLogin, loginError} = useUser();

    const [userName, setUserName] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        if(loginError != null) {
            enqueueSnackbar(loginError ?? '', {variant: 'error', autoHideDuration: 3000})
        }
    }, [loginError]);


    useEffect(() => {
        const isLoggedIn = localStorage.getItem(STORAGE_KEY.AuthToken);

        if (isLoggedIn) {
            navigate('/');
        }
    }, [navigate]);

    const onChangeUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserName(event.target.value);
    }

    const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    }

    const onClickLogin = async () => {
        try {
            var userLoginInfo: any = await userLogin(userName, password);
            setUserName('');
            setPassword('');
            if (userLoginInfo != null && userLoginInfo["access_token"] != null) {
                localStorage.setItem(STORAGE_KEY.AuthToken, userLoginInfo["access_token"]);
                enqueueSnackbar('Login Successfully', {variant: 'success', autoHideDuration: 3000})
                navigate('/');
            }

        } catch (error) {

            enqueueSnackbar('Login Failed', {variant: 'error', autoHideDuration: 3000})
            console.log(error);
        }

    }

    return (<Box padding={0}>
            <LoginLayout
                userName={userName}
                password={password}
                onChangeUserName={onChangeUserName}
                onChangePassword={onChangePassword}
                onClickLogin={onClickLogin}/>
        </Box>)
}

export default Login;
