import { ThemeProvider } from '@mui/material';
import React from 'react';
import { theme } from '../theme';
import { UserProvider } from './UserProvider';

interface ProviderProps {
    children: React.ReactNode;
}

const Provider = ({ children }: ProviderProps) => {
  return (
    <ThemeProvider theme={ theme }>
        <UserProvider>
            { children }
        </UserProvider>
    </ThemeProvider>
  )
}

export default Provider;
