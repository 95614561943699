import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import {STORAGE_KEY} from "../../constants";
import { useNavigate } from 'react-router-dom';
import './Header.css';
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import {AccountCircle, Logout} from "@mui/icons-material";
import MenuComponent from "../menu";


interface HeaderProps {
    onClickDrawerMenu: () => void;
}

const Header = ({ onClickDrawerMenu }: HeaderProps) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onClickProfile = () => {
        handleClose();
        navigate('/profile', );
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleClick = (event : any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleLogout = () => {
        localStorage.removeItem(STORAGE_KEY.AuthToken);
        localStorage.removeItem(STORAGE_KEY.UserDetails);
        navigate('/login', { replace: true });
    };

    return (
        <div>
            <AppBar position="static"  style={{backgroundColor : 'rgb(0, 101, 117)'}}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={handleDrawerOpen}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography sx={{ flexGrow: 1,  textAlign: 'center', fontWeight: 'bold', fontFamily: 'Arial, sans-serif' }} variant="h6">
                        CRRC
                    </Typography>
                    <MenuItem >
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            {/*<MenuItem onClick={onClickProfile} >*/}
                            {/*    <IconButton>*/}
                            {/*        <Person2 fontSize="small" />*/}
                            {/*    </IconButton>*/}
                            {/*    Profile*/}
                            {/*</MenuItem>*/}
                            <MenuItem onClick={handleLogout}>
                                <IconButton>
                                    <Logout fontSize="small" />
                                </IconButton>
                                Logout
                            </MenuItem>
                        </Menu>
                    </MenuItem>
                    <IconButton onClick={handleClick}>
                        <AccountCircle style={{height : '45px',width : '45px', color : "white"}}/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                anchor="left" open={open} onClose={handleDrawerClose} variant="temporary"
                PaperProps={{ elevation: 9 }}
            >
                <MenuComponent open={open} onClose={handleDrawerClose} />
            </Drawer>
        </div>


    )
}

export default Header;


