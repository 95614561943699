export const metData: any = {
  user: [
    {
      name: "oidPkFld",
      type: "INPUT",
      placeholder: "Id",
    },
    {
      name: "id",
      type: "INPUT",
      placeholder: "id",
    },
    {
      name: "firstNameFld",
      type: "INPUT",
      placeholder: "First Name",
    },
    {
      name: "lastNameFld",
      type: "INPUT",
      placeholder: "Last Name",
    },
    {
      name: "employeeIDFld",
      type: "INPUT",
      placeholder: "Employee Id",
    },
    {
      name: "emailFld",
      type: "INPUT",
      placeholder: "Email",
    },
    {
      name: "phoneFld",
      type: "INPUT",
      maxLength: 10,
      placeholder: "Phone",
    },
    {
      name: "mobileFld",
      type: "INPUT",
      maxLength: 10,
      placeholder: "Mobile",
    },
    {
      name: "modifiedByFld",
      type: "INPUT",
      placeholder: "User Type",
    },
    {
      name: "tSFld",
      type: "INPUT",
      placeholder: "KYC No",
    },
  ],
  newTickets: [
    {
      name: "oidPkFld",
      type: "INPUT",
      placeholder: "Station",
    },
    // {
    //     name: 'ticketIdFld',
    //     type: 'INPUT',
    //     placeholder: 'Ticket Id'
    // },
    {
      name: "jobdetailsFld",
      type: "INPUT",
      placeholder: "job Details",
    },
    {
      name: "remarksFld",
      type: "INPUT",
      placeholder: "Remarks",
    },
  ],

  approvalTickets: [
    {
      name: "ticketIdFld",
      type: "INPUT",
      placeholder: "Tickets ID",
    },
    {
      name: "creationdateFld",
      type: "INPUT",
      placeholder: "Creation date",
    },
    {
      name: "jobdateFld",
      type: "INPUT",
      placeholder: "job date",
    },
    {
      name: "statusFld",
      type: "INPUT",
      placeholder: "Status",
    },
  ],

  newUser: [
    {
      name: "titleFld",
      type: "INPUT",
      placeholder: "Title",
    },
    {
      name: "firstNameFld",
      type: "INPUT",
      placeholder: "First Name",
    },
    {
      name: "middleNameFld",
      type: "INPUT",
      placeholder: "Middle Name",
    },
    {
      name: "lastNameFld",
      type: "INPUT",
      placeholder: "Last Name",
    },
    {
      name: "emailFld",
      type: "INPUT",
      placeholder: "Email",
    },
    {
      name: "phoneFld",
      type: "INPUT",
      placeholder: "Phone",
    },
    {
      name: "mobileFld",
      type: "INPUT",
      maxLength: 10,
      placeholder: "Mobile",
    },
  ],
  device: [
    {
      name: "oidPkFld",
      type: "INPUT",
      placeholder: "Id",
    },
    {
      name: "deviceIDFld",
      type: "INPUT",
      placeholder: "Device Id",
    },
    {
      name: "deviceNameFld",
      type: "INPUT",
      placeholder: "Device Name",
    },
    {
      name: "deviceGroupFKFld",
      type: "INPUT",
      placeholder: "Device Group",
    },
    {
      name: "custom1Fld",
      type: "INPUT",
      placeholder: "Device Access Log",
    },
    {
      name: "custom2Fld",
      type: "INPUT",
      placeholder: "Device API",
    },
    {
      name: "geoLocationFld",
      type: "INPUT",
      placeholder: "Device Location",
    },
    {
      name: "remarksFld",
      type: "INPUT",
      placeholder: "Remarks",
    },
  ],
  newDevice: [
    {
      name: "oidPkFld",
      type: "INPUT",
      placeholder: "Id",
    },
    {
      name: "deviceIDFld",
      type: "INPUT",
      placeholder: "Device Id",
    },
    {
      name: "deviceNameFld",
      type: "INPUT",
      placeholder: "Device Name",
    },
    {
      name: "remarksFld",
      type: "INPUT",
      placeholder: "Remarks",
    },
  ],
  wareHouse: [
    {
      name: "oidPkFld",
      type: "INPUT",
      placeholder: "Id",
    },
    {
      name: "warehouseIdFld",
      type: "INPUT",
      placeholder: "Station Id",
    },
    {
      name: "warehouseNameFld",
      type: "INPUT",
      placeholder: "Station Name",
    },
  ],
  warehouses: [
    {
      name: "oidPkFld",
      type: "INPUT",
      placeholder: "Id",
    },
    {
      name: "warehouseIdFld",
      type: "INPUT",
      placeholder: "Station Id",
    },
    {
      name: "warehouseNameFld",
      type: "INPUT",
      placeholder: "Station Name",
    },
  ],
  warehouses_rel: [
    {
      name: "warehouseNameFld",
      type: "DROPDOWN",
      placeholder: "Station Name",
      source: "warehouseList",
    },
  ],

  newWareHouse: [
    {
      name: "oidPkFld",
      type: "INPUT",
      placeholder: "Id",
    },
    {
      name: "warehouseIdFld",
      type: "INPUT",
      placeholder: "Ware House Id",
    },
    {
      name: "warehouseNameFld",
      type: "INPUT",
      placeholder: "Warehouse Name",
    },
  ],

  bidding_detail: [
    {
      name: "id",
      type: "INPUT",
      placeholder: "id",
    },
    {
      name: "name",
      type: "INPUT",
      placeholder: "Name",
    },
    {
      name: "status",
      type: "INPUT",
      placeholder: "status",
    },
  ],
  project_document: [
    {
      name: "id",
      type: "INPUT",
      placeholder: "id",
    },
    {
      name: "fileName",
      type: "INPUT",
      placeholder: "File Name",
    },
    {
      name: "fileType",
      type: "INPUT",
      placeholder: "File Type",
    },
  ],
  projects: [
    {
      name: "id",
      type: "INPUT",
      placeholder: "id",
    },
    {
      name: "description",
      type: "INPUT",
      placeholder: "Description",
    },
    {
      name: "startDate",
      type: "DATE",
      placeholder: "Start Date",
    },
    {
      name: "endDate",
      type: "DATE",
      placeholder: "End Date",
    },
    {
      name: "amount",
      type: "AMOUNT",
      placeholder: "Amount",
    },
  ],
  createProject: [
    {
      name: "title",
      type: "INPUT",
      placeholder: "Title",
    },
    {
      name: "description",
      type: "INPUT",
      placeholder: "Description",
    },
    {
      name: "startDate",
      type: "DATE",
      placeholder: "Start Date",
    },
    {
      name: "endDate",
      type: "DATE",
      placeholder: "End Date",
    },
    {
      name: "amount",
      type: "AMOUNT",
      placeholder: "Amount",
    },
    {
      name: "vendor",
      type: "MULTIDROPDOWN",
      placeholder: "Vendor",
    },
  ],
  timelines: [
    {
      name: "title",
      type: "INPUT",
      placeholder: "Item",
    },
    {
      name: "description",
      type: "INPUT",
      placeholder: "Description",
    },
    {
      name: "startDate",
      type: "DATE",
      placeholder: "Start Date",
    },
    {
      name: "endDate",
      type: "DATE",
      placeholder: "End Date",
    },
    {
      name: "amount",
      type: "AMOUNT",
      placeholder: "Amount",
    },
  ],
  quotes: [
    {
      name: "id",
      type: "INPUT",
      placeholder: "id",
    },
    {
      name: "companyName",
      type: "INPUT",
      placeholder: "Vendor Name",
    },
    {
      name: "status",
      type: "INPUT",
      placeholder: "Status",
    },
    {
      name: "lastUpdated",
      type: "INPUT",
      placeholder: "Last Updated",
    },
    {
      name: "description",
      type: "INPUT",
      placeholder: "Description",
    },
  ],
  vendor: [
    {
      name: "id",
      type: "INPUT",
      placeholder: "ID",
    },
    {
      name: "name",
      type: "INPUT",
      placeholder: "Company Name",
    },
    {
      name: "address",
      type: "INPUT",
      placeholder: "Address",
    },
    {
      name: "phoneNumber",
      type: "INPUT",
      placeholder: "Mobile Number",
    },
    {
      name: "email",
      type: "INPUT",
      placeholder: "Email Address",
    },
  ],
  vendorBankDetails: [
    {
      name: "bankName",
      type: "INPUT",
      placeholder: "Bank Name",
    },
    {
      name: "bankAddress",
      type: "INPUT",
      placeholder: "Bank Address",
    },
    {
      name: "accountNumber",
      type: "INPUT",
      placeholder: "Account Number",
    },
    {
      name: "ifscCode",
      type: "INPUT",
      placeholder: "IFSC Code",
    },
    {
      name: "accountType",
      type: "INPUT",
      placeholder: "Account Type",
    },
  ],
  vendorBusiness: [
    {
      name: "manufacturing",
      type: "INPUT",
      placeholder: "Manufacturing",
    },
    {
      name: "authorisedDealer",
      type: "INPUT",
      placeholder: "Authorised Dealer",
    },
    {
      name: "trader",
      type: "INPUT",
      placeholder: "Trader",
    },
  ],
  vendorCompanies: [
    {
      name: "gstNumber",
      type: "INPUT",
      placeholder: "Gst Number",
    },
    {
      name: "panNumber",
      type: "INPUT",
      placeholder: "Pan Number",
    },
    {
      name: "registeredCompany",
      type: "INPUT",
      placeholder: "Registered Company",
    },
    {
      name: "iecNumber",
      type: "INPUT",
      placeholder: "Iec Number",
    },
    {
      name: "msmeNumber",
      type: "INPUT",
      placeholder: "MSME Number",
    },
    {
      name: "registeredMSME",
      type: "INPUT",
      placeholder: "Registered MSME",
    },
  ],
  vendorDesignations: [
    {
      name: "personName",
      type: "INPUT",
      placeholder: "Person Name",
    },
    {
      name: "designation",
      type: "INPUT",
      placeholder: "Designation",
    },
    {
      name: "contactNumber",
      type: "INPUT",
      placeholder: "Contact Number",
    },
    {
      name: "email",
      type: "INPUT",
      placeholder: "Email",
    },
  ],
  vendorFinancialDetails: [
    {
      name: "particulars",
      type: "INPUT",
      placeholder: "Particulars",
    },
    {
      name: "yearOne",
      type: "INPUT",
      placeholder: "Year One",
    },
    {
      name: "yearTwo",
      type: "INPUT",
      placeholder: "Year Two",
    },
    {
      name: "yearThree",
      type: "INPUT",
      placeholder: "Year Three",
    },
  ],
  vendorMainClients: [
    {
      name: "companyNameAndAddress",
      type: "INPUT",
      placeholder: "Company name and Address",
    },
    {
      name: "referencePersonNameAndDesignation",
      type: "INPUT",
      placeholder: "Reference Person Name and Designation",
    },
    {
      name: "phoneNumber",
      type: "INPUT",
      placeholder: "Phone Number",
    },
    {
      name: "productSupplied",
      type: "INPUT",
      placeholder: "Product Supplied",
    },
    {
      name: "orderValueOrAnnualBusiness",
      type: "INPUT",
      placeholder: "Order Value or Annual Business",
    },
  ],
  vendorSkills: [
    {
      name: "name",
      type: "DROPDOWN",
      placeholder: "Name",
    },
    {
      name: "image",
      type: "IMG",
      placeholder: "Image",
      hidden: true,
    },
  ],
  skills: [
    {
      name: "id",
      type: "INPUT",
      placeholder: "id",
    },
    {
      name: "name",
      type: "DROPDOWN",
      placeholder: "Name",
    },
    {
      name: "image",
      type: "IMG",
      placeholder: "Image",
      hidden: true,
    },
  ],
};
