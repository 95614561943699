import React, { useEffect, useState } from "react";
import Table from "../../components/Table";
import { useUser } from "../../hooks/useUser";
import Loader from "../../components/Loader/Loader";
import { Box } from "@mui/material";
import PaginationComponent from "components/PaginationComponent";

const PendingTickets = () => {
  const { getAllTickets, ticketList, useUserLoading, ticketsCount } = useUser();

  useEffect(() => {
    getAllTickets(currentPage);
  }, []);

  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = async (
    _event: React.ChangeEvent<unknown>,
    pageNumber: number
  ) => {
    await getAllTickets(pageNumber - 1);
    setCurrentPage(pageNumber);
  };

  return (
    <Box>
      {useUserLoading ? (
        <Loader></Loader>
      ) : (
        <Box>
          <h1>Ticket List</h1>
          <Table
            listData={ticketList}
            hyperlinkPropertyName={"oidPkFld"}
            entity={"newTickets"}
          />
          <Box>
            <PaginationComponent
              count={ticketsCount}
              page={currentPage}
              handleChange={handlePageChange}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PendingTickets;
