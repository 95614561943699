import React, {useEffect} from 'react';
import {metData} from "../../constants/meta";
import FromModal from "../../components/Form";
import {useLocation, useNavigate} from "react-router-dom";
import {useUser} from "../../hooks/useUser";
import Loader from "../../components/Loader/Loader";

const WareHouseDetails = () => {
    const location = useLocation();
    const {getWareHouseById, wareHouseDetail, useUserLoading} = useUser();
    const wareHouseDetails: any = location?.state?.data || {};
    const navigate = useNavigate();

    useEffect(() => {
        if(wareHouseDetails && wareHouseDetails.data && wareHouseDetails.data.oidPkFld != null){
            getWareHouseById( wareHouseDetails.data.oidPkFld);
        } else  {
            navigate('/', { replace: true });
        }
    }, []);

    return (
    <div> {useUserLoading ? <Loader></Loader> : <div>
        {
            wareHouseDetail ?
                <FromModal buttonTitle={'Update'} title={'Station Details'}
                           onChange={() => {}}
                           readOnly={true}
                           data={wareHouseDetail}
                           meta={metData.wareHouse}
                           handleClose={() => {}}
                           open={true}
                           onClickPrimary={() => {}}/> : <div></div>
        }
    </div>
    }

    </div>
  )
}

export default WareHouseDetails;


