import {
  MenuItem,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Select,
  Box,
} from "@mui/material";

interface RelayRoomDropdownProps {
  value: {
    branchIdFld?: string;
  };
  wareHouseList: any[];
  onChangeBranchIdFld: (event: SelectChangeEvent<string>) => void;
}

const RelayRoomDropdown = ({
  value,
  onChangeBranchIdFld,
  wareHouseList,
}: RelayRoomDropdownProps) => {
  return (
    <Box sx={{ justifyContent: "center", alignItems: "center" }}>
      <FormControl
        style={{
          width: "70%",
          flexDirection: "column",
        }}
      >
        <InputLabel>Station List</InputLabel>
        <MenuItem
          style={{ color: "grey", flexDirection: "column" }}
          value=""
          disabled
        ></MenuItem>
        <Select
          value={value.branchIdFld ?? ""}
          onChange={onChangeBranchIdFld}
          displayEmpty
        >
          {wareHouseList?.map((item: any) => (
            <MenuItem
              key={item.oidPkFld}
              style={{ color: "grey", flexDirection: "column" }}
              value={item.oidPkFld}
            >
              {item.warehouseNameFld}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default RelayRoomDropdown;
