import React, {useState} from 'react';
import {Button, Stack} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {enqueueSnackbar} from "notistack";
import {useUser} from "../../hooks/useUser";

const ChangePassword = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const {changeNewPassword} = useUser();
    const {oidPkFld} = useParams()


    const handleChange = (e: any) => {
        const { name, value } = e.target;
        if (name === 'currentPassword') {
            setCurrentPassword(value);
        } else if (name === 'newPassword') {
            setNewPassword(value);
        } else {
            setConfirmPassword(value);
        }
    };

    const handleSubmit = async (e: any) => {
        console.log(oidPkFld);
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setMessage('New password and confirm password do not match.');
            return;
        } else if (currentPassword === '' || newPassword === '' || confirmPassword === '') {
            setMessage("Please enter mandatory field");
            return;
        }

        var changePasswordInfo: any = await changeNewPassword(currentPassword,newPassword,oidPkFld);
        console.log(changePasswordInfo);
        if(changePasswordInfo != null && changePasswordInfo.response == null ) {
            enqueueSnackbar('Password Change Successfull', { variant: 'success',autoHideDuration: 3000 } )
            navigate('/login', { replace: true });
        }
        else
            enqueueSnackbar('Please Enter Valid Credentials', { variant: 'error',autoHideDuration: 3000 } )
    };

    const handleClose = () => {
        navigate('/user' );
    };

    return (
        <div style={{  width: "300px",
            margin: "0 auto",
            marginTop: "100px",
            padding:" 40px",
            borderRadius: "10px",
            backgroundColor: "#f7f7f7",
            boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.2)"}} className="change-password-container">
            <h2>Change Password</h2>
            <form onSubmit={handleSubmit}>
                <div style={{  marginBottom: "15px"}} className="form-group">
                    <label style={ {display: 'block', marginBottom: '5px'}}>Current Password:</label>
                    <input  style={{width: '100%', padding: '8px', fontSize: '16px', border:'1px solid #ccc', borderRadius:'5px'}}
                        type="password"
                        name="currentPassword"
                        value={currentPassword}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div style={{  marginBottom: "15px"}} className="form-group">
                    <label style={ {display: 'block', marginBottom: '5px'}}>New Password:</label>
                    <input style={{width: '100%', padding: '8px', fontSize: '16px', border:'1px solid #ccc', borderRadius:'5px'}}
                        type="password"
                        name="newPassword"
                        value={newPassword}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div  style={{  marginBottom: "15px"}} className="form-group">
                    <label style={ {display: 'block', marginBottom: '5px'}}>Repeat New Password:</label>
                    <input  style={{width: '100%', padding: '8px', fontSize: '16px', border:'1px solid #ccc', borderRadius:'5px'}}
                        type="password"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={handleChange}
                        required
                    />
                </div>
                <Stack direction="row" spacing={20}>
                    <Button style={{backgroundColor: "#3361FF"}} variant="outlined"
                            onClick={handleClose}>Cancel</Button>
                    <Button style={{backgroundColor: "#3361FF"}} variant="contained"
                            onClick={handleSubmit}>Save</Button>
                </Stack>
            </form>
            {message  && <p style={{color: 'red'}}>{message}</p>}
        </div>
    );
};

export default ChangePassword;
