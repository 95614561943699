import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Pagination } from "@mui/material";
import { PAGINATION_PER_PAGE } from "config/const";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    width: "70%",
    margin: "auto",
    marginTop: theme.spacing(2),
  },
}));

interface PaginationProps {
  count: number;
  page: number;
  handleChange: (event: React.ChangeEvent<unknown>, page: number) => void;
}

const PaginationComponent: React.FC<PaginationProps> = ({
  count,
  page,
  handleChange,
}: PaginationProps) => {
  const classes = useStyles();

  return count > 0 ? (
    <Box className={classes.root}>
      <Pagination
        count={Math.ceil(count / PAGINATION_PER_PAGE)}
        page={page}
        onChange={handleChange}
      />
    </Box>
  ) : (
    <Box></Box>
  );
};

export default PaginationComponent;
