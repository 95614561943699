import React, { useEffect, useState } from "react";
import AddNewModel from "../AddNewModel";
import { enqueueSnackbar } from "notistack";
import { useUser } from "../../hooks/useUser";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";

interface AddNewModalProps {
  emailFld: string;
  lastNameFld: string;
  phoneFld: string;
  titleFld: string;
  mobileFld: string;
  middleNameFld: string;
  firstNameFld: string;
  enabled: boolean;
  passwordFld: string;
  passwordHintFld: any;
  remarksFld: any;
  userTypeFld: any;
  employeeFld: string;
  kycFld: string;
  companyIdFld: any;
  branchIdFld: any;
}

const NewUser = () => {
  const {  userSignUp, useUserLoading } = useUser();
  const { getCompanyDropDown, companyDropDownList } = useUser();
  const { getBranchDropDown, branchDropDownList } = useUser();
  const { getUserTypeDropDown, userTypeDropDownList } = useUser();
  const [open, setOpen] = useState<boolean>(true);
  const alphanumericPattern = /^[a-zA-Z0-9]*$/;
  const numbericPattern = /^[0-9]{10}$/;
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%^*?&])[A-Za-z\d@$!%^*?&]{8,}$/;
  const minLength = 10;
  const minPassword = 7;
   const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const navigate = useNavigate();


  useEffect(() => {
    getCompanyDropDown();
    getBranchDropDown();
    getUserTypeDropDown("USERTYPE");
  }, []);

  const [value, setValue] = useState<AddNewModalProps>({
    emailFld: "",
    lastNameFld: "",
    phoneFld: "",
    titleFld: "",
    mobileFld: "",
    middleNameFld: "",
    firstNameFld: "",
    enabled: true,
    passwordFld: "",
    passwordHintFld: "",
    remarksFld: "",
    userTypeFld: "",
    employeeFld: "",
    kycFld: "",
    companyIdFld: "",
    branchIdFld: "",
  });

  useEffect(() => {
    if (userTypeDropDownList != null) {
      const filteredUserType = userTypeDropDownList.filter(
        (x: any) => x.valueFld === "PERSON"
      );
      console.log(filteredUserType);
      if (filteredUserType && filteredUserType.length > 0) {
        setValue({
          ...value,
          userTypeFld: filteredUserType[0].oidPkFld,
        });
      }
    }
  }, [userTypeDropDownList]);

  const onChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue({
      ...value,
      lastNameFld: event.target.value,
    });
  };
  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue({
      ...value,
      emailFld: event.target.value,
    });
  };
  const onChangeFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue({
      ...value,
      firstNameFld: event.target.value,
    });
  };
  const onChangeMiddleName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue({
      ...value,
      middleNameFld: event.target.value,
    });
  };

  const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue({
      ...value,
      passwordFld: event.target.value,
    });
  };

  const onChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue({
      ...value,
      phoneFld: event.target.value,
    });
  };
  const onChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue({
      ...value,
      titleFld: event.target.value,
    });
  };

  const onChangeUserTypeFld = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue({
      ...value,
      userTypeFld: event.target.value,
    });
  };

  const onChangeKycFld = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue({
      ...value,
      kycFld: event.target.value,
    });
  };
  const onChangeMobile = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue({
      ...value,
      mobileFld: event.target.value,
    });
  };

  const onChangeEmployeeFld = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue({
      ...value,
      employeeFld: event.target.value,
    });
  };

  const onChangeCompanyIdFld = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue({
      ...value,
      companyIdFld: event.target.value,
    });
  };

  const onChangeBranchIdFld = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.value);
    setValue({
      ...value,
      branchIdFld: event.target.value,
    });
  };

  const onCloseModal = () => {
    setOpen(false);
    navigate("/");
  };

  const onClickConfirm = async () => {
    if (
      value.firstNameFld === "" ||
      value.lastNameFld === "" ||
      value.passwordFld === "" ||
      value.mobileFld === "" ||
      value.emailFld === "" ||
      value.employeeFld === "" ||
      value.branchIdFld === "" ||
      value.companyIdFld === ""
    ) {
      enqueueSnackbar("Please enter mandatory field", {
        variant: "error",
        autoHideDuration: 3000,
      });
      return true;
    } else if (!alphanumericPattern.test(value.firstNameFld)) {
      enqueueSnackbar("First Name must contain only alphanumeric characters", {
        variant: "error",
        autoHideDuration: 3000,
      });
      return true;
    } else if (!alphanumericPattern.test(value.middleNameFld)) {
      enqueueSnackbar("Middle Name must contain only alphanumeric characters", {
        variant: "error",
        autoHideDuration: 3000,
      });
      return true;
    } else if (!alphanumericPattern.test(value.lastNameFld)) {
      enqueueSnackbar("Last Name must contain only alphanumeric characters", {
        variant: "error",
        autoHideDuration: 3000,
      });
      return true;
    } else if (!emailPattern.test(value.emailFld)) {
      enqueueSnackbar("Invalid email address", {
        variant: "error",
        autoHideDuration: 3000,
      });
      return true;
    } else if (!numbericPattern.test(value.mobileFld) && minLength) {
      enqueueSnackbar("Invalid mobile number. Please enter a valid 10-digit", {
        variant: "error",
        autoHideDuration: 3000,
      });
      return true;
    } else if (!alphanumericPattern.test(value.kycFld)) {
      enqueueSnackbar("KYC NO must contain only alphanumeric characters", {
        variant: "error",
        autoHideDuration: 3000,
      });
      return true;
    } else if (!passwordRegex.test(value.passwordFld) && minPassword) {
      enqueueSnackbar(
        "Password does not match the standard validation\nPassword should contain\n1.Password should be 7 characters\n2.Atleast one alphanumeric character\n3.Atleast one special characters\n4. Atleast one upper case",
        { variant: "error", autoHideDuration: 3000 }
      );
      return true;
    } else {
      try {
        await userSignUp({
          branchIdFld: value.branchIdFld,
          companyIdFld: value.companyIdFld,
          employeeFld: value.employeeFld,
          kycFld: value.kycFld,
          enabled: true,
          emailFld: value.emailFld,
          firstNameFld: value.firstNameFld,
          lastNameFld: value.lastNameFld,
          middleNameFld: value.middleNameFld,
          mobileFld: value.mobileFld,
          phoneFld: value.phoneFld,
          titleFld: value.titleFld,
          passwordFld: value.passwordFld,
          passwordHintFld: value.passwordFld,
          remarksFld: value.remarksFld,
          userTypeFld: value.userTypeFld,
        }).then((r) => {
          console.log(r);
          if (r?.response && r?.response?.status === 400) {
            enqueueSnackbar(r?.response?.data, {
              variant: "error",
              autoHideDuration: 3000,
            });
          } else {
            enqueueSnackbar(r.message, {
              variant: "success",
              autoHideDuration: 3000,
            });
            window.history.back();
          }
        });
        setOpen(false);
      } catch (e: any) {
        console.log(e);
        setOpen(false);
      }
    }
  };

  return (
    <div>
      {useUserLoading ? (
        <Loader></Loader>
      ) : (
        <div>
          <AddNewModel
            open={open}
            value={value}
            handleClose={onCloseModal}
            onClickPrimary={onClickConfirm}
            onChangeEmail={onChangeEmail}
            onChangeFirstName={onChangeFirstName}
            onChangeLastName={onChangeLastName}
            onChangeMiddleName={onChangeMiddleName}
            onChangeMobile={onChangeMobile}
            onChangePhone={onChangePhone}
            onChangePassword={onChangePassword}
            onChangeTitle={onChangeTitle}
            onChangeEmployeeFld={onChangeEmployeeFld}
            onChangeKycFld={onChangeKycFld}
            onChangeUserTypeFld={onChangeUserTypeFld}
            companyList={companyDropDownList}
            branchList={branchDropDownList}
            userTypeList={userTypeDropDownList}
            onChangeBranchIdFld={onChangeBranchIdFld}
            onChangeCompanyIdFld={onChangeCompanyIdFld}
          ></AddNewModel>
        </div>
      )}
    </div>
  );
};

export default NewUser;
