import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import JobDurationTracker from "../../components/JobDurationTracker";

const style = {
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  height: "80vh",
};

interface AddNewTicketModalProps {
  open: boolean;
  value: {
    approverIdFld: string;
    jobDetails: string;
    jobDuration: any;
    remarks: string;
    ticketTypeFld: any;
    wareHouseIdFld: any;
    jobAttachmentsFld: any;
  };
  onClickPrimary: () => void;
  handleClose: () => void;
  wareHouseList: any[];
  ticketTypeList: any[];
  onChangeJobDetailsFld: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeRemarksFld: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeApproverRemarksFld: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  onChangeWarehouse: any;
  onChangeTicketTypeFld: any;
  onChangejobAttachmentsFld: any;
  onChangeJobDateFld: any;
  onChangeJobDurationFld: any;
  startDate: any;
  setStartDate: any;
  selectedFile: any;
  setSelectedFile: any;
  handleFileChange: any;
  onChangeDuration: any;
  onChange: (event: Date | string | null) => void;
}

const AddNewTicketModel = ({
  open,
  value,
  onClickPrimary,
  handleClose,
  onChangeApproverRemarksFld,
  onChangeRemarksFld,
  onChangeJobDetailsFld,
  wareHouseList,
  onChangeWarehouse,
  ticketTypeList,
  onChangeTicketTypeFld,
  onChangeJobDateFld,
  onChange,
  startDate,
  setStartDate,

  onChangeJobDurationFld,
  setSelectedFile,
  selectedFile,
  handleFileChange,
  onChangeDuration,
  onChangejobAttachmentsFld,
}: AddNewTicketModalProps) => {
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box sx={style} flexDirection={"row"}>
          <Typography
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            New Tickets
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            {wareHouseList != null ? (
              <FormControl style={{ width: "30%" }}>
                <InputLabel>Station</InputLabel>
                <MenuItem
                  style={{ color: "grey", flexDirection: "column" }}
                  value="Select Company"
                >
                  {" "}
                </MenuItem>
                <Select
                  value={value.wareHouseIdFld}
                  onChange={(event: SelectChangeEvent<string>) =>
                    onChangeWarehouse(event)
                  }
                  displayEmpty
                >
                  {wareHouseList.map((item: any) => (
                    <MenuItem
                      style={{ color: "grey", flexDirection: "column" }}
                      value={item.oidPkFld}
                    >
                      {item.warehouseNameFld}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <div></div>
            )}
            {ticketTypeList != null ? (
              <FormControl style={{ width: "30%" }}>
                <InputLabel>Ticket Type</InputLabel>
                <MenuItem
                  style={{ color: "grey", flexDirection: "column" }}
                  value="Select Device Group"
                >
                  {" "}
                </MenuItem>
                <Select
                  value={value.ticketTypeFld}
                  onChange={(event: SelectChangeEvent<string>) =>
                    onChangeTicketTypeFld(event)
                  }
                  displayEmpty
                >
                  {ticketTypeList.map((item: any) => (
                    <MenuItem
                      style={{ color: "grey", flexDirection: "column" }}
                      value={item.oidPkFld}
                    >
                      {item.valueFld}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <div></div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <JobDurationTracker
              duration={value.jobDuration}
              onChangeDuration={onChangeDuration}
            ></JobDurationTracker>
            <TextField
              style={{ width: "30%" }}
              multiline={false}
              minRows={"1"}
              placeholder="Job Details"
              name="jobDetailsFld"
              value={value.jobDetails}
              onChange={onChangeJobDetailsFld}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignContent: "center",
              alignItems: "center",
            }}
          >
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              style={{ width: "30%" }}
              multiline={false}
              minRows={"1"}
              placeholder="Approver"
              name="approverRemarksFld"
              value={value.approverIdFld}
              onChange={onChangeApproverRemarksFld}
            />
            <TextField
              style={{ width: "30%" }}
              multiline={false}
              minRows={"1"}
              placeholder="Remarks"
              name="remarksFld"
              value={value.remarks}
              onChange={onChangeRemarksFld}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <DatePicker
              sx={{ width: "30%" }}
              onChange={(date) => setStartDate(date)}
            />
            <TextField
              style={{ width: "30%" }}
              multiline={false}
              minRows={"1"}
              placeholder="Attachments"
              name="jobAttachmentsFld"
              value={value.jobAttachmentsFld}
              onChange={onChangejobAttachmentsFld}
            />
            {selectedFile && (
              <p
                style={{ width: "30%", height: "10%", flexDirection: "column" }}
              >
                Selected file: {selectedFile.name}
              </p>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Stack direction="row" spacing={10}>
              <Button
                style={{ backgroundColor: "#3361FF" }}
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                style={{ backgroundColor: "#3361FF" }}
                variant="contained"
                onClick={onClickPrimary}
              >
                Submit
              </Button>
            </Stack>
          </div>
        </Box>
      </LocalizationProvider>
    </div>
  );
};

export default AddNewTicketModel;
