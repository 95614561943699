import React, { useEffect } from "react";
import Table from "../../components/Table";
import { useUser } from "../../hooks/useUser";
import Loader from "../../components/Loader/Loader";
import { Box } from "@mui/material";

const WareHouseList = () => {
  const { getAllWareHouse, wareHouseList, useUserLoading } = useUser();

  useEffect(() => {
    getAllWareHouse();
  }, []);

  return (
    <Box>
      {useUserLoading ? (
        <Loader></Loader>
      ) : (
        <Box>
          <h1>Station List</h1>
          <Table
            listData={wareHouseList}
            hyperlinkPropertyName={"oidPkFld"}
            entity={"wareHouse"}
          ></Table>
        </Box>
      )}
    </Box>
  );
};

export default WareHouseList;
