import { useCallback } from "react";
import { useUser } from "./useUser";

export const useDropdown = () => {
  const { getAllWareHouse } = useUser();

  const getListForDropdown: (source: string) => Promise<any[]> = useCallback(
    async (source: string) => {
      try {
        switch (source) {
          case "warehouseList":
            let wareHouses = await getAllWareHouse();
            return wareHouses.map((x: any) => {
              return { name: x.warehouseNameFld, id: x.oidPkFld };
            });
          default:
            return [];
        }
      } catch (e) {
        console.log(e);
        return [];
      }
    },
    []
  );

  return {
    getListForDropdown,
  };
};
