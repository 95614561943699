import {
    Box,
    Button,
    FormControl,
    InputLabel, MenuItem,
    Select, SelectChangeEvent,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import React from 'react';

const style = {
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    height: '80vh'
};

interface AddNewDeviceModalProps {
    open: boolean;
    value: {
        deviceGroup: any;
        deviceNameFld: string;
        deviceIDFld: string;
        custom1Fld: string,
        custom2Fld: string,
        custom3Fld: string,
        custom4Fld: string,
        custom5Fld: string,
        geoLocationFld: string,
        enabled: boolean;
        remarksFld: string,

    }
    onClickPrimary: () => void;
    handleClose: () => void;

    deviceList: any[];

    onChangeDeviceNameFld: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeDeviceIDFld: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeCustom1Fld: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeCustom2Fld: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeCustom3Fld: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeCustom4Fld: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeCustom5Fld: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeGeoLocationFld: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeRemarksFld: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeDeviceGroup: any;


}

const AddNewDeviceModel = ({ open, value,  onClickPrimary, handleClose, onChangeDeviceIDFld,onChangeDeviceNameFld, onChangeCustom1Fld, onChangeCustom2Fld,onChangeCustom3Fld, onChangeCustom4Fld, onChangeCustom5Fld,onChangeDeviceGroup, onChangeRemarksFld, onChangeGeoLocationFld, deviceList}: AddNewDeviceModalProps) => {
    return (
        <div>
                <Box sx={style} flexDirection={'row'}>
                    <Typography style={{ display: 'flex',justifyContent: 'center', alignContent: 'center', alignItems: 'center', textAlign:'center'}} id="modal-modal-title" variant="h6" component="h2">
                        New Device
                    </Typography>
                    <div style={{display: 'flex', flexDirection: 'row',  justifyContent: 'space-evenly', alignContent: 'center', alignItems: 'center'}}>
                        <TextField style={{width: '30%',}} multiline={false} minRows={"1"} placeholder="Device Id*" name="deviceIDFld" value={ value.deviceIDFld } onChange={ onChangeDeviceIDFld } />
                        <TextField style={{width: '30%',}} multiline={false} minRows={"1"} placeholder="Device Name*" name="deviceNameFld" value={ value.deviceNameFld } onChange={ onChangeDeviceNameFld } />

                    </div>

                    <div style={{display: 'flex', flexDirection: 'row',  justifyContent: 'space-evenly', alignContent: 'center', alignItems: 'center'}}>
                        { deviceList != null ?
                            <FormControl style={{ width: '30%' }}>
                                <InputLabel >Device Group*</InputLabel>
                                <MenuItem style={{color: 'grey', flexDirection: 'column'}}  value="Select Device Group"> </MenuItem>
                                <Select
                                    value={value.deviceGroup}
                                    onChange={(event: SelectChangeEvent<string>) => onChangeDeviceGroup(event)}
                                    displayEmpty>
                                    {deviceList.map((item : any) => (
                                        <MenuItem style={{color: 'grey', flexDirection: 'column', }}  value={item.oidPkFld}>{item.valueFld}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            : <div></div> }
                        <TextField style={{width: '30%',}} multiline={false} minRows={"1"} placeholder="Device Access Log" name="custom1Fld" value={ value.custom1Fld } onChange={ onChangeCustom1Fld } />
                    </div>

                   <div style={{display: 'flex', flexDirection: 'row',  justifyContent: 'space-evenly', alignContent: 'center', alignItems: 'center'}}>
                       <TextField style={{width: '30%',}} multiline={false} minRows={"1"} placeholder="Device API" name="custom2Fld" value={ value.custom2Fld } onChange={ onChangeCustom2Fld } />
                       <TextField style={{width: '30%',}} multiline={false} minRows={"1"} placeholder="Device Ip" name="custom3Fld" value={ value.custom3Fld } onChange={ onChangeCustom3Fld } />
                   </div>

                    <div style={{display: 'flex', flexDirection: 'row',  justifyContent: 'space-evenly', alignContent: 'center', alignItems: 'center'}}>
                        <TextField style={{width: '30%',}} multiline={false} minRows={"1"} placeholder="Device Location" name="geoLocationFld" value={ value.geoLocationFld } onChange={ onChangeGeoLocationFld } />
                        <TextField style={{width: '30%',}} multiline={false} minRows={"1"} placeholder="Remarks" name="remarksFld" value={ value.remarksFld } onChange={ onChangeRemarksFld } />
                    </div>

                    <div style={{display: 'flex', flexDirection: 'row',  justifyContent: 'center', alignContent: 'center', alignItems: 'center'}}>
                    <Stack direction="row" spacing={ 10 } >
                        <Button style={{ backgroundColor : "#3361FF"}}  variant="outlined" onClick={ handleClose }>Cancel</Button>
                        <Button style={{ backgroundColor : "#3361FF"}}  variant="contained" onClick={ onClickPrimary }>Save</Button>
                    </Stack>
                        </div>
                </Box>
        </div>

    )
}

export default AddNewDeviceModel;
