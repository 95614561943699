import React from 'react';

const OpenTickets = () => {
    return (
        <div>
            <h2>Ops Tickets</h2>
            {/*<Grid container spacing={ 4 } paddingLeft={'25px'} paddingTop={'40px'} direction={'column'}>*/}
            {/*    <Card  sx={{width: '350px', cursor: 'pointer', borderRadius: '15px',margin : "20px",backgroundColor: 'lightcyan'}}>*/}
            {/*        <Box sx={{p: 2, display: 'flex'}}>*/}
            {/*            <Stack spacing={0.5} style={{flexGrow: 1}}>*/}
            {/*                <Stack  style={{justifyContent: "center",alignItems: 'center'}}>*/}
            {/*                    <Typography fontWeight={700} fontSize={16}>New Ticket</Typography>*/}
            {/*                </Stack>*/}

            {/*            </Stack>*/}
            {/*        </Box>*/}
            {/*        <Divider/>*/}
            {/*    </Card>*/}
            {/*    <Card sx={{width: '350px', cursor: 'pointer', borderRadius: '15px',margin : "20px",backgroundColor: 'lightcyan'}}>*/}
            {/*        <Box sx={{p: 2, display: 'flex'}}>*/}
            {/*            <Stack spacing={0.5} style={{flexGrow: 1}}>*/}
            {/*                <Stack  style={{justifyContent: "center",alignItems: 'center'}}>*/}
            {/*                    <Typography fontWeight={700} fontSize={16}>pending Tickets</Typography>*/}
            {/*                </Stack>*/}

            {/*            </Stack>*/}
            {/*        </Box>*/}
            {/*        <Divider/>*/}
            {/*    </Card>*/}
            {/*    <Card sx={{width: '350px', cursor: 'pointer', borderRadius: '15px',margin : "20px",backgroundColor: 'lightcyan'}}>*/}
            {/*        <Box sx={{p: 2, display: 'flex'}}>*/}
            {/*            <Stack spacing={0.5} style={{flexGrow: 1}}>*/}
            {/*                <Stack  style={{justifyContent: "center",alignItems: 'center'}}>*/}
            {/*                    <Typography fontWeight={700} fontSize={16}>Approval Tickets</Typography>*/}
            {/*                </Stack>*/}

            {/*            </Stack>*/}
            {/*        </Box>*/}
            {/*        <Divider/>*/}
            {/*    </Card>*/}
            {/*</Grid>*/}
        </div>
    )
}

export default OpenTickets;
