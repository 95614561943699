import React, {useEffect, useState} from 'react';
import {useUser} from "../../hooks/useUser";
import {enqueueSnackbar} from "notistack";
import AddNewDeviceModel from "../AddNewDeviceModel";
import {useNavigate} from "react-router-dom";
import Loader from "../../components/Loader/Loader";

interface CreateDeviceRequestProps {
    deviceNameFld: string;
    deviceIDFld: string;
    custom1Fld: string,
    custom2Fld: string,
    custom3Fld: string,
    custom4Fld: string,
    custom5Fld: string,
    geoLocationFld: string,
    enabled: boolean;
    remarksFld: string,
    deviceGroup: any;
}

const NewDevice = () => {
    const {  newDevices , useUserLoading} = useUser();
    const {getDeviceDropDown, deviceDropDownList} = useUser();
    const [open, setOpen] = useState<boolean>(true);
    const navigate = useNavigate();

    const [value, setValue, ] = useState<CreateDeviceRequestProps>({
        remarksFld: '',
        deviceIDFld: '',
        deviceNameFld: '',
        custom1Fld: '',
        custom2Fld: '',
        custom3Fld: '',
        custom4Fld: '',
        custom5Fld: '',
        geoLocationFld: '',
        deviceGroup: '',
        enabled: true

    });

    useEffect(() => {
        getDeviceDropDown('DEVICEGROUP');
    }, []);


    const onCloseModal = () => {
        setOpen(false);
        navigate('/' );
    };

    const onChangeDeviceNameFld = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            deviceNameFld: event.target.value,
        });
    };

    const onChangeDeviceIDFld = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            deviceIDFld: event.target.value,
        });
    };
    const onChangeCustom1Fld = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            custom1Fld: event.target.value,
        });
    };
    const onChangeCustom5Fld = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            custom5Fld: event.target.value,
        });
    };
    const onChangeCustom2Fld = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            custom2Fld: event.target.value,
        });
    };
    const onChangeCustom3Fld = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            custom3Fld: event.target.value,
        });
    };

    const onChangeCustom4Fld = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            custom4Fld: event.target.value,
        });
    };
    const onChangeGeoLocationFld = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            geoLocationFld: event.target.value,
        });
    };
    const onChangeRemarksFld = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            remarksFld: event.target.value,
        });
    };

    const onChangeDeviceGroup = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            deviceGroup: event.target.value,
        });
    };

    const onClickConfirm = async () => {
        if(value.deviceNameFld === '' || value.deviceIDFld ==='' || value.deviceGroup === ''){
            enqueueSnackbar("Please enter mandatory field", { variant: 'error',autoHideDuration: 3000 } )
            return true;
        } else if(value.deviceNameFld.length < 5 ){
            enqueueSnackbar("Device Name Need at least 5 characters", { variant: 'error',autoHideDuration: 3000 } )
            return true;

        }else {
            try {
                await newDevices({
                    deviceGroup: value.deviceGroup,
                    custom1Fld: value.custom1Fld,
                    custom2Fld: value.custom2Fld,
                    custom3Fld: value.custom3Fld,
                    custom4Fld: value.custom4Fld,
                    custom5Fld: value.custom5Fld,
                    geoLocationFld: value.geoLocationFld,
                    remarksFld: value.remarksFld,
                    deviceIDFld: value.deviceIDFld,
                    deviceNameFld: value.deviceNameFld,
                    enabled: true

                }).then(r => {
                    console.log(r);
                    if(r?.response && r?.response?.status === 400){
                        enqueueSnackbar(r?.response?.data, { variant: 'error',autoHideDuration: 3000 } )
                    }else {
                        enqueueSnackbar(r.message, { variant: 'success',autoHideDuration: 3000 } )
                        window.history.back()

                    }
                });
                setOpen(false);
            } catch (e : any) {
                console.log(e)
                setOpen(false);
            }
        }
    };
    return (
        <div>
            {useUserLoading ? <Loader></Loader> : <div>
                <AddNewDeviceModel
                    open={open}
                    value={value}
                    onClickPrimary={onClickConfirm}
                    handleClose={onCloseModal}
                    onChangeDeviceNameFld={onChangeDeviceNameFld}
                    onChangeDeviceIDFld={onChangeDeviceIDFld}
                    onChangeCustom1Fld={onChangeCustom1Fld}
                    onChangeCustom2Fld={onChangeCustom2Fld}
                    onChangeCustom3Fld={onChangeCustom3Fld}
                    onChangeCustom4Fld={onChangeCustom4Fld}
                    onChangeCustom5Fld={onChangeCustom5Fld}
                    onChangeGeoLocationFld={onChangeGeoLocationFld}
                    onChangeRemarksFld={onChangeRemarksFld}
                    onChangeDeviceGroup={onChangeDeviceGroup}
                    deviceList={deviceDropDownList}></AddNewDeviceModel>
            </div>}

        </div>

    )
}

export default NewDevice;
