import { useCallback, useState } from "react";
import { AxiosInstance } from "axios";
import getApiConfig from "../config/apiConfig";
import { userEndpoint } from "../constants/EndPoint/user";

export interface CreateUserRequestProps {
  emailFld: string;
  lastNameFld: string;
  phoneFld: string;
  titleFld: string;
  mobileFld: string;
  middleNameFld: string;
  firstNameFld: string;
  enabled: true;
  passwordFld: any;
  passwordHintFld: any;
  remarksFld: any;
  employeeFld: string;
  userTypeFld: any;
  kycFld: string;
  companyIdFld: any;
  branchIdFld: any;
}
export interface CreateTicketRequestProps {
  approverIdFld: string;
  jobDetails: string;
  jobDuration: any;
  remarks: string;
  ticketTypeFld: any;
  wareHouseIdFld: any;
}

export interface CreateWareHouseRequestProps {
  warehouseNameFld: string;
  warehouseIdFld: string;
  custom1Fld: string;
  custom2Fld: string;
  custom3Fld: string;
  custom4Fld: string;
  custom5Fld: string;
  geoLocationFld: string;
  enabled: true;
  remarksFld: string;
  branchIdFld: string;
  addressIdFld: string;
}

export interface CreateDeviceRequestProps {
  deviceNameFld: string;
  deviceIDFld: string;
  custom1Fld: string;
  custom2Fld: string;
  custom3Fld: string;
  custom4Fld: string;
  custom5Fld: string;
  geoLocationFld: string;
  enabled: true;
  remarksFld: string;
  deviceGroup: any;
}

export const useUser = () => {
  const apiConfig: AxiosInstance = getApiConfig();
  const [userList, setUserList] = useState<any | []>();
  const [userCount, setUserCount] = useState<any | []>();
  const [deviceCount, setDeviceCount] = useState<any | []>();
  const [ticketsCount, setTicketsCount] = useState<any | []>();
  const [companyDropDownList, setCompanyList] = useState<any | []>();
  const [branchDropDownList, setBranchList] = useState<any | []>();
  const [deviceDropDownList, setDeviceDropdownList] = useState<any | []>();
  const [userTypeDropDownList, setUserTypeList] = useState<any | []>();
  const [activationType, setActivationType] = useState<any | []>();
  const [ticketList, setTicketList] = useState<any | []>();
  const [wareHouseList, setWareHouseList] = useState<any | []>();
  const [stationAudit, setStationAudit] = useState<any | []>();
  const [relayRoomStatus, setRelayRoomStatus] = useState<any | []>();
  const [deviceList, setDeviceList] = useState<any | []>();
  const [userDetail, setUserDetail] = useState<any | {}>();
  const [approvalList, setApprovalList] = useState<any | {}>();
  const [MyTicketsList, setMyTickets] = useState<any | {}>();
  const [ticketDetail, setTicketDetail] = useState<any | {}>();
  const [wareHouseDetail, setWareHouseDetail] = useState<any | {}>();
  const [deviceDetail, setDeviceDetail] = useState<any | {}>();
  const [changePassword, setChangePassword] = useState<any | {}>();
  const [loginDetail, setLoginDetail] = useState<any | {}>();
  const [loginError, setLoginError] = useState<any | "">();
  const [newWareHouseDetail, setNewWareHouseDetail] = useState<any | {}>();
  const [newDevice, setNewDevice] = useState<any | {}>();
  const [newTicket, setNewTickets] = useState<any | {}>();
  const [signUpDetail, setSignUpDetail] = useState<any | {}>();
  const [useUserLoading, setUseUserLoading] = useState<boolean>(false);

  const getAllUser = useCallback(
    async (pageNumber?: number) => {
      try {
        setUseUserLoading(true);
        const response = await apiConfig.get(
          `${userEndpoint.ALL_USER}?page=${pageNumber}&size=10`
        );
        setUseUserLoading(false);
        if (response.data) {
          const { users, count } = response.data;
          if (typeof window !== "undefined") {
            setUserList([...users]);
            setUserCount(count);
          }
          return count;
        }
      } catch (e) {
        setUseUserLoading(false);
        return e;
      }
    },
    [apiConfig]
  );

  const getUserById = useCallback(
    async (oidPkFld: any) => {
      try {
        const response = await apiConfig.get(
          userEndpoint.USER_BY_ID + oidPkFld
        );
        if (response.data) {
          if (typeof window !== "undefined") {
            setUserDetail({
              ...response.data,
            });
          }
        }
      } catch (e) {
        return e;
      }
    },
    [apiConfig]
  );
  const getApprovalTicketList = useCallback(
      async () => {
        try {
          const response = await apiConfig.get(
              userEndpoint.APPROVED_LIST
          );
          if (response.data) {
            if (typeof window !== "undefined") {
              setApprovalList([...response.data]);
            }
          }
        } catch (e) {
          return e;
        }
      },
      [apiConfig]
  );

  const getMyTickets = useCallback(
      async () => {
        try {
          const response = await apiConfig.get(
              userEndpoint.MYTICKETS_LIST
          );
          if (response.data) {
            if (typeof window !== "undefined") {
              setMyTickets([...response.data]);
            }
          }
        } catch (e) {
          return e;
        }
      },
      [apiConfig]
  );

  const getTicketById = useCallback(
    async (oidPkFld: any) => {
      try {
        setUseUserLoading(true);
        const response = await apiConfig.get(
          userEndpoint.TICKET_BY_ID + oidPkFld
        );
        setUseUserLoading(false);
        if (response.data) {
          if (typeof window !== "undefined") {
            setTicketDetail({
              ...response.data,
            });
          }
        }
      } catch (e) {
        setUseUserLoading(false);
        return e;
      }
    },
    [apiConfig]
  );

  const userLogin = useCallback(
    async (email: any, password: any) => {
      try {
        const response = await apiConfig.post(userEndpoint.LOGIN, {
          email: email,
          password: password,
        });
        if (response.status == 200) {
          if (response.data) {
            if (typeof window !== "undefined") {
              setLoginDetail({
                ...response.data,
              });
            }
            return response.data;
          }
        } else {
          console.log(response);
          console.log(response.data);
          setLoginError({
            ...response.data,
          });
        }
      } catch (e: any) {
        setLoginError(e.response.data["message"] ?? "");
        return e;
      }
    },
    [apiConfig]
  );

  const setActivation = useCallback(
    async (activateTypeFld: any, userIDFKFld: any) => {
      try {
        const response = await apiConfig.post(userEndpoint.SET_ACTIVATION, {
          activateTypeFld: activateTypeFld ? "Y" : "N",
          userIDFKFld: userIDFKFld,
        });
        if (response.data) {
          if (typeof window !== "undefined") {
            setActivationType({
              ...response.data,
            });
          }
          return response.data;
        }
      } catch (e) {
        return e;
      }
    },
    [apiConfig]
  );

  const userSignUp = useCallback(
    async (payload: CreateUserRequestProps) => {
      try {
        setUseUserLoading(true);
        const response = await apiConfig.post(userEndpoint.SIGN_UP, payload);
        setUseUserLoading(false);
        if (response.data) {
          if (typeof window !== "undefined") {
            setSignUpDetail({
              ...response.data,
            });
          }
          return response.data;
        }
      } catch (e) {
        setUseUserLoading(false);
        return e;
      }
    },
    [apiConfig]
  );

  const newWareHouse = useCallback(
    async (payload: CreateWareHouseRequestProps) => {
      try {
        setUseUserLoading(true);
        const response = await apiConfig.post(
          userEndpoint.WAREHOUSE_CREATE,
          payload
        );
        setUseUserLoading(false);
        if (response.data) {
          if (typeof window !== "undefined") {
            setNewWareHouseDetail({
              ...response.data,
            });
          }
          return response.data;
        }
      } catch (e) {
        setUseUserLoading(false);
        return e;
      }
    },
    [apiConfig]
  );

  const newDevices = useCallback(
    async (payload: CreateDeviceRequestProps) => {
      try {
        setUseUserLoading(true);
        const response = await apiConfig.post(
          userEndpoint.DEVICE_CREATE,
          payload
        );
        setUseUserLoading(false);
        if (response.data) {
          if (typeof window !== "undefined") {
            setNewDevice({
              ...response.data,
            });
          }
          return response.data;
        }
      } catch (e) {
        setUseUserLoading(false);
        return e;
      }
    },
    [apiConfig]
  );

  const newTickets = useCallback(
    async (payload: CreateTicketRequestProps) => {
      try {
        setUseUserLoading(true);
        const response = await apiConfig.post(
          userEndpoint.TICKETS_CREATE,
          payload
        );
        setUseUserLoading(false);
        if (response.data) {
          if (typeof window !== "undefined") {
            setNewTickets({
              ...response.data,
            });
          }
          return response.data;
        }
      } catch (e) {
        setUseUserLoading(false);
        return e;
      }
    },
    [apiConfig]
  );

  const updateUserDetails = useCallback(
    async (payload: any) => {
      try {
        setUseUserLoading(true);
        let warehouseList = payload.warehouses.map((x: any) => x.oidPkFld);
        const response = await apiConfig.post(
          userEndpoint.UPDATE_USER_MAPPING,
          {
            userIDFKFld: payload.oidPkFld,
            warehouseIDFKFld: warehouseList,
          }
        );
        setUseUserLoading(false);
        if (response.data) {
          await getUserById(payload.oidPkFld);
          return response.data;
        }
      } catch (e) {
        setUseUserLoading(false);
        return e;
      }
    },
    [apiConfig]
  );

  const updateDeviceDetails = useCallback(
    async (payload: any) => {
      try {
        setUseUserLoading(true);
        let warehouseList = payload.warehouses.map((x: any) => x.oidPkFld);
        const response = await apiConfig.post(
          userEndpoint.UPDATE_DEVICE_MAPPING,
          {
            deviceIDFld: payload.oidPkFld,
            warehouseIDFKFld: warehouseList,
          }
        );
        setUseUserLoading(false);
        if (response.data) {
          await getUserById(payload.oidPkFld);
          return response.data;
        }
      } catch (e) {
        setUseUserLoading(false);
        return e;
      }
    },
    [apiConfig]
  );

  const getAllTickets = useCallback(
    async (pageNumber?: any) => {
      try {
        setUseUserLoading(true);
        const response = await apiConfig.get(
          `${userEndpoint.ALL_TICKETS}?page=${pageNumber}&size=10`
        );

        setUseUserLoading(false);
        if (response.data) {
          const { tickets, count } = response.data;
          if (typeof window !== "undefined") {
            setTicketList([...tickets]);
            setTicketsCount(count);
          }
          return count;
        }
      } catch (e) {
        setUseUserLoading(false);
        return e;
      }
    },
    [apiConfig]
  );

  const getAllWareHouse = useCallback(async () => {
    try {
      setUseUserLoading(true);
      const response = await apiConfig.get(userEndpoint.ALL_WAREHOUSE);
      setUseUserLoading(false);
      console.log(response);
      console.log(response.data);
      if (response.data) {
        if (typeof window !== "undefined") {
          setWareHouseList([...response.data]);
        }
        return response.data;
      }
      return [];
    } catch (e) {
      setUseUserLoading(false);
      return e;
    }
  }, [apiConfig]);

  const getWareHouseById = useCallback(
    async (oidPkFld: any) => {
      try {
        setUseUserLoading(true);
        const response = await apiConfig.get(
          userEndpoint.WAREHOUSE_BY_ID + oidPkFld
        );
        setUseUserLoading(false);
        if (response.data) {
          if (typeof window !== "undefined") {
            setWareHouseDetail({
              ...response.data,
            });
          }
        }
      } catch (e) {
        setUseUserLoading(false);
        return e;
      }
    },
    [apiConfig]
  );

  const getStationAudit = useCallback(
    async (wareHouseId: number) => {
      try {
        setUseUserLoading(true);
        const response = await apiConfig.get(
          userEndpoint.STATION_AUDIT + wareHouseId
        );
        setUseUserLoading(false);

        if (response.data) {
          if (typeof window !== "undefined") {
            setStationAudit([...response.data]);
          }
          return response.data;
        }
        return [];
      } catch (e) {
        setUseUserLoading(false);
        return e;
      }
    },
    [apiConfig]
  );

  const getRelayRoomStatus = useCallback(
    async (wareHouseId: number) => {
      try {
        setUseUserLoading(true);
        const response = await apiConfig.get(
          userEndpoint.RELAY_ROOM_STATUS + "?warehouseID=" + wareHouseId
        );
        setUseUserLoading(false);
        if (response.data) {
          if (typeof window !== "undefined") {
            setRelayRoomStatus({ ...response.data });
          }
          return response.data;
        }
        return [];
      } catch (e) {
        setUseUserLoading(false);
        return e;
      }
    },
    [apiConfig]
  );

  const getDevice = useCallback(
    async (pageNumber: number) => {
      try {
        setUseUserLoading(true);
        const response = await apiConfig.get(
          `${userEndpoint.ALL_DEVICE}?page=${pageNumber}&size=10`
        );
        setUseUserLoading(false);
        if (response.data) {
          const { devices, count } = response.data;
          if (typeof window !== "undefined") {
            setDeviceList([...devices]);
            setDeviceCount(count);
          }
          return count;
        }
      } catch (e) {
        setUseUserLoading(false);
        return e;
      }
    },
    [apiConfig]
  );

  const getDeviceById = useCallback(
    async (oidPkFld: any) => {
      try {
        const response = await apiConfig.get(
          userEndpoint.DEVICE_BY_ID + oidPkFld
        );
        if (response.data) {
          if (typeof window !== "undefined") {
            setDeviceDetail({
              ...response.data,
            });
          }
        }
      } catch (e) {
        return e;
      }
    },
    [apiConfig]
  );

  const getCompanyDropDown = useCallback(async () => {
    try {
      const response = await apiConfig.get(userEndpoint.COMPANY_DROPDOWN);
      if (response.data) {
        if (typeof window !== "undefined") {
          setCompanyList([...response.data]);
        }
      }
    } catch (e) {
      return e;
    }
  }, [apiConfig]);

  const getBranchDropDown = useCallback(async () => {
    try {
      const response = await apiConfig.get(userEndpoint.BRANCH_DROPDOWN);
      if (response.data) {
        if (typeof window !== "undefined") {
          setBranchList([...response.data]);
        }
      }
    } catch (e) {
      return e;
    }
  }, [apiConfig]);

  const getUserTypeDropDown = useCallback(
    async (type: any) => {
      try {
        const response = await apiConfig.get(
          userEndpoint.USER_TYPE_DROPDOWN_BY_TYPE_ID + type
        );
        if (response.data) {
          if (typeof window !== "undefined") {
            setUserTypeList([...response.data]);
          }
        }
      } catch (e) {
        return e;
      }
    },
    [apiConfig]
  );

  const getDeviceDropDown = useCallback(
    async (type: any) => {
      try {
        const response = await apiConfig.get(
          userEndpoint.DEVICE_DROPDOWN_BY_TYPE_ID + type
        );
        if (response.data) {
          if (typeof window !== "undefined") {
            setDeviceDropdownList([...response.data]);
          }
        }
      } catch (e) {
        return e;
      }
    },
    [apiConfig]
  );

  const changeNewPassword = useCallback(
    async (currentPassword: any, newPassword: any, userIdFld: any) => {
      try {
        const response = await apiConfig.post(userEndpoint.CHANGE_PASSWORD, {
          currentPassword: currentPassword,
          newPassword: newPassword,
          userIdFld: userIdFld,
        });
        if (response.data) {
          if (typeof window !== "undefined") {
            setChangePassword({
              ...response.data,
            });
            return response.data;
          }
        }
      } catch (e) {
        return e;
      }
    },
    [apiConfig]
  );

  return {
    getAllUser,
    userList,
    userDetail,
    getUserById,
    userLogin,
    loginDetail,
    wareHouseList,
    getAllWareHouse,
    wareHouseDetail,
    getWareHouseById,
    deviceList,
    getDevice,
    userCount,
    deviceCount,
    ticketsCount,
    deviceDetail,
    getDeviceById,
    userSignUp,
    signUpDetail,
    newWareHouse,
    newWareHouseDetail,
    newDevice,
    newTicket,
    setNewWareHouseDetail,
    newDevices,
    setNewDevice,
    setNewTickets,
    newTickets,
    getAllTickets,
    ticketList,
    setTicketDetail,
    ticketDetail,
    getTicketById,
    getCompanyDropDown,
    setCompanyList,
    companyDropDownList,
    getBranchDropDown,
    branchDropDownList,
    setBranchList,
    getApprovalTicketList,
    approvalList,
    getMyTickets,
    MyTicketsList,
    setUserTypeList,
    getUserTypeDropDown,
    userTypeDropDownList,
    getDeviceDropDown,
    getStationAudit,
    stationAudit,
    getRelayRoomStatus,
    relayRoomStatus,
    setDeviceDropdownList,
    deviceDropDownList,
    updateUserDetails,
    updateDeviceDetails,
    setActivation,
    setActivationType,
    activationType,
    changeNewPassword,
    changePassword,
    setChangePassword,
    useUserLoading,
    loginError,
  };
};
