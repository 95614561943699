import axios, { AxiosInstance } from "axios";
import { configuration } from "./config";
import { STORAGE_KEY } from "../constants";

const getApiConfig = (): AxiosInstance => {
  const apiConfig = axios.create({
    baseURL : "https://nextecx.com/crrc/",
    // baseURL: 'http://192.168.0.176:8081',
    headers: {
      "Content-Type": "application/json",
    },
  });

  // Add an interceptor for responses
  apiConfig.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        // Session expired or unauthorized, handle accordingly
        localStorage.clear();
        window.location.href = "/login";
      }
      return Promise.reject(error);
    }
  );

  // Get the bearer token from local storage
  const token = localStorage.getItem(STORAGE_KEY.AuthToken);
  // const parseToken = JSON.parse(token);

  // If the token exists, add it to the Authorization header
  if (token) {
    apiConfig.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  return apiConfig;
};

export default getApiConfig;
