import React, { createContext, useContext, useState, useMemo } from 'react';

export interface UserProps {
  id: string;
  name: string;
  email: string;
  userName: string;
  role: string;
}

export interface ContextType {
  userData: UserProps;
  setUserData(f: UserProps | ((prev: UserProps) => UserProps)): void;
}

interface UserProviderProps {
  children: React.ReactNode;
}

const UserContext = createContext<ContextType>({} as ContextType);

export default UserContext;

export function useUser() {
  return useContext(UserContext);
}

export const UserProvider = ({
  children,
}: UserProviderProps) => {
  const [userData, setUserData] = useState<UserProps>({
    id: '',
    name: '',
    email: '',
    userName: '',
    role: 'ROLE_CLIENT',
  });

  const contextValue = useMemo(() => (
    { userData, setUserData }
  ), [userData, setUserData]);

  return (
    <UserContext.Provider value={ contextValue }>
      { children }
    </UserContext.Provider>
  );
};
