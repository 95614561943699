import { useEffect, useState } from "react";
import popupStyles from "./custom-popup.module.css";
import PropTypes from "prop-types";
import * as React from "react";

interface CustomPopupProps {
    show: boolean;
    title: string;
    onClick: () => void;
    children: React.ReactNode;
    onClose: (event: boolean) => void;
}

const CustomPopup = ({ show, onClose, children, title, onClick }: CustomPopupProps) => {
    const [showModel, setShow] = useState(show);

    const closeHandler = () => {
        setShow(false);
        onClose(false); // Notify parent component that the popup is closed
    };

    useEffect(() => {
        setShow(show);
    }, [show]);

    return (
        <div
            style={{
                visibility: showModel ? "visible" : "hidden",
                opacity: showModel ? "1" : "0",
            }}
            className={popupStyles.overlay}
        >
            <div className={popupStyles.popup}>
                <h2>{title}</h2>
                <button className={`${popupStyles.close} ${popupStyles.button}`} onClick={closeHandler}>
                    Cancel
                </button>
                <button className={`${popupStyles.okButton} ${popupStyles.button}`} onClick={onClick}>
                    Ok
                </button>
            </div>
        </div>
    );
};

CustomPopup.propTypes = {
    title: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    children: PropTypes.node,
};

export default CustomPopup;
