import { Box, Card, Divider, Stack, Typography } from "@mui/material";

const MyDashBoard = () => {
  return (
    <div>
      <Stack
        spacing={3.0}
        direction={"row"}
        justifyContent={"center"}
        padding={5}
      >
        <Card sx={{ width: "250px", cursor: "pointer" }}>
          <Box
            sx={{
              p: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Stack
              spacing={0.5}
              style={{
                flexGrow: 1,
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Stack
                direction="row"
                style={{ justifyContent: "space-between" }}
              >
                <Typography fontWeight={700} fontSize={16}>
                  Pending for Approval
                </Typography>
              </Stack>
              <Typography fontWeight={700} fontSize={"42px"}>
                18
              </Typography>
            </Stack>
          </Box>
          <Divider />
        </Card>

        <Card sx={{ width: "250px", cursor: "pointer" }}>
          <Box
            sx={{
              p: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Stack
              spacing={0.5}
              style={{
                flexGrow: 1,
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography fontWeight={700} fontSize={16}>
                Completed Tickets
              </Typography>
              <Typography fontWeight={700} fontSize={"42px"}>
                7
              </Typography>
            </Stack>
          </Box>
          <Divider />
        </Card>
      </Stack>
    </div>
  );
};

export default MyDashBoard;
