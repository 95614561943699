import React from "react";
import { Box, Card, Typography } from "@mui/material";
import { formatDistanceToNow } from "date-fns";

interface StationAuditProps {
  stationAudit: { messageFld: string; creationDateTimeFld: string }[];
}

interface FormattedDate {
  formattedDate: string;
}

const StationAudit: React.FC<StationAuditProps> = ({ stationAudit }) => {
  if (!Array.isArray(stationAudit) || stationAudit.length === 0) {
    return null;
  }

  return (
    <Box>
      {stationAudit.map((data, index) => {
        const formattedDate: FormattedDate = {
          formattedDate: formatDistanceToNow(
            new Date(data.creationDateTimeFld),
            {
              addSuffix: true,
            }
          ),
        };

        return (
          <Card
            key={`${data.messageFld}_${index}`}
            sx={{
              width: "400px",
              cursor: "pointer",
              position: "relative",
              p: 1,
              marginBottom: "10px",
            }}
          >
            <Box
              sx={{
                p: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                position: "relative",
              }}
            >
              <Typography
                sx={{
                  color: "rgb(0, 101, 117)",
                  fontWeight: "700",
                  fontSize: "16px",
                  borderRadius: "25px",
                  position: "absolute",
                  top: "5px",
                  left: "5px",
                }}
              >
                {data.messageFld}
              </Typography>
              <Typography
                sx={{
                  color: "grey",
                  fontSize: "12px",
                  position: "absolute",
                  bottom: "5px",
                  right: "5px",
                }}
              >
                {formattedDate.formattedDate}
              </Typography>
            </Box>
          </Card>
        );
      })}
    </Box>
  );
};

export default StationAudit;
