import {
    Box,
    Button,
    FormControl,
     InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import React from 'react';

const style = {
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
};

interface AddNewModalProps {
    open: boolean;
    value: {
        emailFld: string;
        lastNameFld: string;
        phoneFld: string;
        titleFld: string;
        mobileFld: string;
        middleNameFld: string;
        firstNameFld: string;
        enabled: boolean;
        passwordFld:string;
        employeeFld:string;
        userTypeFld: any;
        kycFld: string,
        companyIdFld: any,
        branchIdFld: any,

    }
    onClickPrimary: () => void;
    handleClose: () => void;

    branchList: any[];
    companyList: any[];
    userTypeList: any[];

    onChangeTitle: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeFirstName: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeLastName: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeMiddleName: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeMobile: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangePhone: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeEmail: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeEmployeeFld: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeUserTypeFld: any;
    onChangeKycFld: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangePassword: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeCompanyIdFld: any;
    onChangeBranchIdFld: any;


}

const AddNewModel = ({ open, value,  onClickPrimary, handleClose,onChangeFirstName,onChangeLastName, onChangeMiddleName,onChangeMobile, onChangePhone, onChangeEmail, onChangePassword, onChangeEmployeeFld, onChangeUserTypeFld, onChangeKycFld, onChangeCompanyIdFld, onChangeBranchIdFld, companyList,branchList, userTypeList,    }: AddNewModalProps) => {
    return (
      <div>
        <Box sx={style} flexDirection={"row"}>
          <Typography
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            New User
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              style={{ width: "30%" }}
              multiline={false}
              minRows={"1"}
              placeholder="First Name*"
              name="firstNameFld"
              value={value.firstNameFld}
              onChange={onChangeFirstName}
            />
            <TextField
              style={{ width: "30%" }}
              multiline={false}
              minRows={"1"}
              placeholder="Middle Name"
              name="middleNameFld"
              value={value.middleNameFld}
              onChange={onChangeMiddleName}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              style={{ width: "30%" }}
              multiline={false}
              minRows={"1"}
              placeholder="Last Name*"
              name="lastNameFld"
              value={value.lastNameFld}
              onChange={onChangeLastName}
            />
            <TextField
              style={{ width: "30%" }}
              multiline={false}
              minRows={"1"}
              placeholder="Employee Id*"
              name="employeeFld"
              value={value.employeeFld}
              onChange={onChangeEmployeeFld}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            {companyList != null ? (
              <FormControl style={{ width: "30%", flexDirection: "column" }}>
                <InputLabel>Company ID*</InputLabel>
                <MenuItem
                  style={{ color: "grey", flexDirection: "column" }}
                  value=""
                >
                  {" "}
                </MenuItem>
                <Select
                  value={value.companyIdFld}
                  onChange={(event: SelectChangeEvent<string>) =>
                    onChangeCompanyIdFld(event)
                  }
                  displayEmpty
                >
                  {companyList.map((item: any) => (
                    <MenuItem
                      style={{ color: "grey", flexDirection: "column" }}
                      value={item.oidPkFld}
                    >
                      {item.companyNameFld}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <div></div>
            )}

            {branchList != null ? (
              <FormControl style={{ width: "30%", flexDirection: "column" }}>
                <InputLabel>Select Branch*</InputLabel>
                <MenuItem
                  style={{ color: "grey", flexDirection: "column" }}
                  value="Select Branch"
                ></MenuItem>
                <Select
                  value={value.branchIdFld}
                  onChange={(event: SelectChangeEvent<string>) =>
                    onChangeBranchIdFld(event)
                  }
                  displayEmpty
                >
                  {branchList.map((item: any) => (
                    <MenuItem
                      style={{ color: "grey", flexDirection: "column" }}
                      value={item.oidPkFld}
                    >
                      {item.branchNameFld}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <div></div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              style={{ width: "30%" }}
              multiline={false}
              minRows={"1"}
              placeholder="Email*"
              name="emailFld"
              value={value.emailFld}
              onChange={onChangeEmail}
            />
            <TextField
              style={{ width: "30%" }}
              multiline={false}
              minRows={"1"}
              placeholder="Phone"
              name="phoneFld"
              value={value.phoneFld}
              inputProps={{ maxLength: 10 }}
              onChange={onChangePhone}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              style={{ width: "30%" }}
              multiline={false}
              minRows={"1"}
              placeholder="Mobile*"
              name="mobileFld"
              value={value.mobileFld}
              inputProps={{ maxLength: 10 }}
              onChange={onChangeMobile}
            />
            {userTypeList != null ? (
              <FormControl style={{ width: "30%", flexDirection: "column" }}>
                <InputLabel>User Type*</InputLabel>
                <MenuItem
                  style={{ color: "grey", flexDirection: "column" }}
                  value="Select UserType"
                ></MenuItem>
                <Select
                  value={value.userTypeFld}
                  onChange={(event: SelectChangeEvent<string>) =>
                    onChangeUserTypeFld(event)
                  }
                  displayEmpty
                >
                  {userTypeList.map((item: any) => (
                    <MenuItem style={{ color: "grey" }} value={item.oidPkFld}>
                      {item.valueFld}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <div></div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              style={{ width: "30%" }}
              multiline={false}
              minRows={"1"}
              placeholder="KYC No*"
              name="kycFld"
              value={value.kycFld}
              onChange={onChangeKycFld}
            />
            <TextField
              style={{ width: "30%" }}
              multiline={false}
              minRows={"1"}
              placeholder="Password*"
              name="passwordFld"
              value={value.passwordFld}
              onChange={onChangePassword}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Stack direction="row" spacing={10}>
              <Button
                style={{ backgroundColor: "#3361FF" }}
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                style={{ backgroundColor: "#3361FF" }}
                variant="contained"
                onClick={onClickPrimary}
              >
                Save
              </Button>
            </Stack>
          </div>
        </Box>
      </div>
    );
}

export default AddNewModel;
