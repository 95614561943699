import {Box, Card, Divider, Grid, Stack, Typography} from '@mui/material';
import React from 'react';

const PendingForApproval = () => {
    return (
        <div>
            <h2>Pending For Approval</h2>
            <Grid container spacing={ 4 } paddingLeft={'25px'} paddingTop={'40px'}>
                <Card sx={{width: '350px', cursor: 'pointer', borderRadius: '15px',margin : "20px"}}>
                    <Box sx={{p: 2, display: 'flex'}}>
                        <Stack spacing={0.5} style={{flexGrow: 1}}>
                            <Stack direction={"row"} style={{justifyContent: "space-between"}}>
                                <Typography fontWeight={700} fontSize={16}>Ticket Id</Typography>
                                <Typography fontWeight={700} fontSize={16}>013</Typography>
                            </Stack>
                            <Stack direction={"row"} style={{justifyContent: "space-between"}}>
                                <Typography fontWeight={700} fontSize={16}>Creation Date</Typography>
                                <Typography fontWeight={700} fontSize={16}>11/09/2023</Typography>

                            </Stack>
                            <Stack direction={"row"} style={{justifyContent: "space-between"}}>
                                <Typography fontWeight={700} fontSize={16}>Status</Typography>
                                <Typography fontWeight={700} fontSize={16}>Pending</Typography>
                            </Stack>
                        </Stack>
                    </Box>
                    <Divider/>
                </Card>
                <Card sx={{width: '350px', cursor: 'pointer', borderRadius: '15px',margin : "20px"}}>
                    <Box sx={{p: 2, display: 'flex'}}>
                        <Stack spacing={0.5} style={{flexGrow: 1}}>
                            <Stack direction={"row"} style={{justifyContent: "space-between"}}>
                                <Typography fontWeight={700} fontSize={16}>Ticket Id</Typography>
                                <Typography fontWeight={700} fontSize={16}>012</Typography>
                            </Stack>
                            <Stack direction={"row"} style={{justifyContent: "space-between"}}>
                                <Typography fontWeight={700} fontSize={16}>Creation Date</Typography>
                                <Typography fontWeight={700} fontSize={16}>10/09/2023</Typography>

                            </Stack>
                            <Stack direction={"row"} style={{justifyContent: "space-between"}}>
                                <Typography fontWeight={700} fontSize={16}>Status</Typography>
                                <Typography fontWeight={700} fontSize={16}>Pending</Typography>
                            </Stack>
                        </Stack>
                    </Box>
                    <Divider/>
                </Card>
                <Card sx={{width: '350px', cursor: 'pointer', borderRadius: '15px',margin : "20px"}}>
                    <Box sx={{p: 2, display: 'flex'}}>
                        <Stack spacing={0.5} style={{flexGrow: 1}}>
                            <Stack direction={"row"} style={{justifyContent: "space-between"}}>
                                <Typography fontWeight={700} fontSize={16}>Ticket Id</Typography>
                                <Typography fontWeight={700} fontSize={16}>011</Typography>
                            </Stack>
                            <Stack direction={"row"} style={{justifyContent: "space-between"}}>
                                <Typography fontWeight={700} fontSize={16}>Creation Date</Typography>
                                <Typography fontWeight={700} fontSize={16}>09/09/2023</Typography>

                            </Stack>
                            <Stack direction={"row"} style={{justifyContent: "space-between"}}>
                                <Typography fontWeight={700} fontSize={16}>Status</Typography>
                                <Typography fontWeight={700} fontSize={16}>Pending</Typography>
                            </Stack>
                        </Stack>
                    </Box>
                    <Divider/>
                </Card>
                <Card sx={{width: '350px', cursor: 'pointer', borderRadius: '15px',margin : "20px"}}>
                    <Box sx={{p: 2, display: 'flex'}}>
                        <Stack spacing={0.5} style={{flexGrow: 1}}>
                            <Stack direction={"row"} style={{justifyContent: "space-between"}}>
                                <Typography fontWeight={700} fontSize={16}>Ticket Id</Typography>
                                <Typography fontWeight={700} fontSize={16}>010</Typography>
                            </Stack>
                            <Stack direction={"row"} style={{justifyContent: "space-between"}}>
                                <Typography fontWeight={700} fontSize={16}>Creation Date</Typography>
                                <Typography fontWeight={700} fontSize={16}>11/08/2023</Typography>

                            </Stack>
                            <Stack direction={"row"} style={{justifyContent: "space-between"}}>
                                <Typography fontWeight={700} fontSize={16}>Status</Typography>
                                <Typography fontWeight={700} fontSize={16}>Pending</Typography>
                            </Stack>
                        </Stack>
                    </Box>
                    <Divider/>
                </Card>
                <Card sx={{width: '350px', cursor: 'pointer', borderRadius: '15px',margin : "20px"}}>
                    <Box sx={{p: 2, display: 'flex'}}>
                        <Stack spacing={0.5} style={{flexGrow: 1}}>
                            <Stack direction={"row"} style={{justifyContent: "space-between"}}>
                                <Typography fontWeight={700} fontSize={16}>Ticket Id</Typography>
                                <Typography fontWeight={700} fontSize={16}>009</Typography>
                            </Stack>
                            <Stack direction={"row"} style={{justifyContent: "space-between"}}>
                                <Typography fontWeight={700} fontSize={16}>Creation Date</Typography>
                                <Typography fontWeight={700} fontSize={16}>05/08/2023</Typography>

                            </Stack>
                            <Stack direction={"row"} style={{justifyContent: "space-between"}}>
                                <Typography fontWeight={700} fontSize={16}>Status</Typography>
                                <Typography fontWeight={700} fontSize={16}>Pending</Typography>
                            </Stack>
                        </Stack>
                    </Box>
                    <Divider/>
                </Card>
            </Grid>
        </div>
    )
}

export default PendingForApproval;
