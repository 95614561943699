import React, { useEffect, useState } from "react";
import Table from "../../components/Table";
import { useUser } from "../../hooks/useUser";
import Loader from "../../components/Loader/Loader";
import { Box } from "@mui/material";
import PaginationComponent from "components/PaginationComponent";

const Device = () => {
  const { getDevice, deviceList, useUserLoading, deviceCount } = useUser();

  useEffect(() => {
    getDevice(currentPage);
  }, []);

  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    pageNumber: number
  ) => {
    setCurrentPage(pageNumber);
    getDevice(pageNumber - 1);
  };

  return (
    <Box>
      {useUserLoading ? (
        <Loader />
      ) : (
        <Box>
          <h1>Device List</h1>
          <Table
            listData={deviceList}
            hyperlinkPropertyName={"oidPkFld"}
            entity={"device"}
          />
          <Box>
            <PaginationComponent
              count={deviceCount}
              page={currentPage}
              handleChange={handlePageChange}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Device;
