import React, {useEffect, useState} from 'react';
import AddNewWareHouseModel from "../AddNewWareHouseModel";
import {useUser} from "../../hooks/useUser";
import {enqueueSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import Loader from "../../components/Loader/Loader";

interface AddNewWareHouseModalProps {
    warehouseNameFld: string,
    warehouseIdFld: string,
    branchIdFld: string,
    addressFld: string,
    custom1Fld: string,
    custom2Fld: string,
    custom3Fld: string,
    custom4Fld: string,
    custom5Fld: string,
    geoLocationFld: string,
    enabled: boolean;
    remarksFld: string,

}

const NewWareHouse = () => {
    const {  newWareHouse ,useUserLoading } = useUser();
    const [open, setOpen] = useState<boolean>(true);
    const {getBranchDropDown, branchDropDownList} = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        getBranchDropDown();
    }, []);

    const [value, setValue, ] = useState<AddNewWareHouseModalProps>({
        remarksFld: '',
        addressFld: '',
        warehouseNameFld: '',
        branchIdFld: '',
        warehouseIdFld: '',
        custom1Fld: '',
        custom2Fld: '',
        custom3Fld: '',
        custom4Fld: '',
        custom5Fld: '',
        geoLocationFld: '',
        enabled: true


    });
    const onCloseModal = () => {
        setOpen(false);
        navigate('/' );
    };



    const onChangeWarehouseNameFld = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            warehouseNameFld: event.target.value,
        });
    };

    const onChangeWarehouseIdFld = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            warehouseIdFld: event.target.value,
        });
    };
    const onChangeBranchIdFld = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            branchIdFld: event.target.value,
        });
    };

    const onChangeCustom1Fld = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            custom1Fld: event.target.value,
        });
    };
    const onChangeCustom5Fld = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            custom5Fld: event.target.value,
        });
    };
    const onChangeCustom2Fld = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            custom2Fld: event.target.value,
        });
    };
    const onChangeCustom3Fld = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            custom3Fld: event.target.value,
        });
    };
    const onChangeCustom4Fld = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            custom4Fld: event.target.value,
        });
    };
    const onChangeGeoLocationFld = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            geoLocationFld: event.target.value,
        });
    };
    const onChangeRemarksFld = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            remarksFld: event.target.value,
        });
    };

    const onChangeAddressFld = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            addressFld: event.target.value,
        });
    };

    const onClickConfirm = async () => {
        if(value.warehouseNameFld === '' || value.warehouseIdFld === '' || value.branchIdFld === '' || value.addressFld === ''|| value.geoLocationFld === ''){
            enqueueSnackbar("Please enter mandatory field", { variant: 'error',autoHideDuration: 3000 } )
            return true;
        } else if(value.warehouseNameFld.length < 5 ){
            enqueueSnackbar("WareHouse Name Need at least 5 characters", { variant: 'error',autoHideDuration: 3000 } )
            return true;

        }else {
            try {
                await newWareHouse({
                    addressIdFld: value.addressFld,
                    custom1Fld: value.custom1Fld, custom2Fld: value.custom2Fld, custom3Fld: value.custom3Fld, custom4Fld: value.custom4Fld, custom5Fld: value.custom5Fld, geoLocationFld: value.geoLocationFld,
                    remarksFld: value.remarksFld,
                    enabled: true,
                    warehouseIdFld: value.warehouseIdFld, warehouseNameFld: value.warehouseNameFld, branchIdFld: value.branchIdFld
                }).then(r => {
                    console.log(r);
                    if(r?.response && r?.response?.status === 400){
                        enqueueSnackbar(r?.response?.data, { variant: 'error',autoHideDuration: 3000 } )
                    }else {
                        enqueueSnackbar(r.message, { variant: 'success',autoHideDuration: 3000 } )
                        window.history.back()
                    }
                });
                setOpen(false);
            } catch (e : any) {
                console.log(e)
                setOpen(false);
            }
        }
    };

    return (
        <div>
            {
                useUserLoading ? <Loader></Loader> : <div>
                    <AddNewWareHouseModel
                        open={open}
                        value={value}
                        onClickPrimary={onClickConfirm}
                        handleClose={onCloseModal}
                        onChangeWarehouseNameFld={onChangeWarehouseNameFld}
                        onChangeWarehouseIdFld={onChangeWarehouseIdFld}
                        onChangeBranchIdFld={onChangeBranchIdFld}
                        onChangeAddressFld={onChangeAddressFld}
                        onChangeCustom1Fld={onChangeCustom1Fld}
                        onChangeCustom2Fld={onChangeCustom2Fld}
                        onChangeCustom3Fld={onChangeCustom3Fld}
                        onChangeCustom4Fld={onChangeCustom4Fld}
                        onChangeCustom5Fld={onChangeCustom5Fld}
                        onChangeGeoLocationFld={onChangeGeoLocationFld}
                        branchList={branchDropDownList}
                        onChangeRemarksFld={onChangeRemarksFld}></AddNewWareHouseModel>
                </div>
            }
        </div>
    )
}

export default NewWareHouse;
