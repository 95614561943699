import React from "react";
import {Add, Remove} from "@mui/icons-material";

const JobDurationTracker = ({duration, onChangeDuration}:any) => {

    const increaseDuration = () => {
        const newDuration = parseInt(duration ?? 0, 10);
        onChangeDuration(newDuration + 1);
    };

    const decreaseDuration = () => {
        if (duration > 0) {
            const newDuration = parseInt(duration ?? 0, 10);
            onChangeDuration(newDuration - 1);
        }
    };

    const handleInputChange = (e: any) => {
        const newDuration = parseInt(e.target.value, 10);
        onChangeDuration(newDuration || 0);
    };

    return (
        <div style={{
            textAlign: "left",
            margin: "5px",
            paddingTop: '10px',
            paddingBottom: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: "1px solid #ccc",
            borderRadius: "5px",
            width: '30%',
            backgroundColor: 'white',
            color: '#333'
        }} className="duration-container">
            <div className="input-container" style={{ display: 'flex', alignItems: 'center' }}>
                <label style={{ color: 'grey', paddingLeft:'10px', paddingRight: '10px' }}>Job Duration (minutes):{" "}</label>

            </div>
            <div className="button-container" style={{paddingRight: '10px', alignItems: 'center', display: 'flex'}}>
                <input
                    style={{ width: '60px', padding: '5px', border: '1px solid #3361FF', borderRadius: '5px', color: '#3361FF', alignItems: 'center',paddingRight: '10px', }} // Set width to 70px (approximately 30% of the container width)
                    className="duration-input"
                    type="number"
                    value={duration}
                    onChange={handleInputChange}
                />
                <div style={{padding: '5px'}}> </div>
                <Remove  onClick={decreaseDuration} style={{ color: '#3361FF', cursor: 'pointer', backgroundColor: 'lightgray', padding: '5px', borderRadius: '5px',borderSpacing: '10px' }} />
                <div style={{padding: '5px'}}> </div>
                <Add  onClick={increaseDuration} style={{ color: '#3361FF', cursor: 'pointer', backgroundColor: 'lightgray', padding: '5px', borderRadius: '5px' }} /> {/* Added background color and padding to Add button */}
            </div>
        </div>


    );
};

export default JobDurationTracker;
