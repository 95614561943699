import React from 'react';


const Notifications = () => {
  return (
    <div>
      <h2>Notifications</h2>
        {/*<Card sx={{ width: '1000px', cursor: 'pointer',justifyContent: "center"}}>*/}
        {/*    <Box sx={{ p: 20, display: 'flex' }}>*/}
        {/*        <Stack  justifyContent={"center"}>*/}
        {/*            <Stack  direction={"column"} style={{justifyContent : "space-between"}}>*/}
        {/*                <Card sx={{width: '200px', cursor: 'pointer',fontSize:"12", borderRadius: '25px',justifyContent: "center", alignItems: "center", color: colors.grey,margin: '10px'}}>*/}
        {/*                    <ChipListComponent style={{color : 'black',justifyContent: "center", alignItems: "center"}}  text="User"></ChipListComponent></Card>*/}
        {/*                <Card sx={{width: '200px', cursor: 'pointer', fontSize:"12",borderRadius: '25px',justifyContent: "center", alignItems: "center", color: colors.grey,margin: '10px'}}>*/}
        {/*                    <ChipListComponent style={{color : 'black',justifyContent: "center", alignItems: "center"}}  text="Warehouse"></ChipListComponent></Card>*/}
        {/*                <Card sx={{width: '200px', cursor: 'pointer',fontSize:"12", borderRadius: '25px',justifyContent: "center", alignItems: "center", color: colors.grey,margin: '10px'}}>*/}
        {/*                    <ChipListComponent style={{color : 'black',justifyContent: "center", alignItems: "center"}}  text="Device"></ChipListComponent></Card>*/}
        {/*                <Card sx={{width: '200px', cursor: 'pointer',fontSize:"12", borderRadius: '25px',justifyContent: "center", alignItems: "center", color: colors.grey,margin: '10px',}}>*/}
        {/*                    <ChipListComponent style={{color : 'black',justifyContent: "center", alignItems: "center"}}  text="Tickets"></ChipListComponent></Card>*/}
        {/*                <Card sx={{width: '200px', cursor: 'pointer',fontSize:"12", borderRadius: '25px',justifyContent: "center", alignItems: "center", color: colors.grey,margin: '10px',}}>*/}
        {/*                    <ChipListComponent style={{color : 'black',justifyContent: "center", alignItems: "center"}}  text="Notification"></ChipListComponent></Card>*/}

        {/*            </Stack>*/}
        {/*        </Stack>*/}
        {/*    </Box>*/}
        {/*    <Divider />*/}


        {/*</Card>*/}
    </div>
  )
}

export default Notifications;
