import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import { STORAGE_KEY } from '../../constants';
import { useUser } from '../../provider/UserProvider';
import { useLocation} from 'react-router-dom';
import "../../components/Topbar/Topbar.css"
import { useNavigate } from 'react-router-dom';
import {useSnackbar} from "notistack";
import Header from "../../components/Header";

interface AppLayoutProps {
    children: React.ReactNode;
}

const AppLayout = ({ children }: AppLayoutProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { setUserData } = useUser();
  const location = useLocation();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const { userData } = useUser();
    const { enqueueSnackbar } = useSnackbar();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleClick = (event : any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

  const onClickDrawerMenu = () => {
    setIsOpen(!isOpen);
  };
  const isLoginPage = location.pathname === '/login';

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onClickProfile = () => {
        handleClose();
        navigate('/vendorProfile', );
    };

    const handleLogout = () => {
        handleClose();
        localStorage.removeItem(STORAGE_KEY.AuthToken);
        localStorage.removeItem(STORAGE_KEY.UserDetails);
        navigate('/login', { replace: true });
        enqueueSnackbar("Logout Successfully", { variant: 'success',autoHideDuration: 3000 } )
    };


  useEffect(() => {
    const userDetails = localStorage.getItem(STORAGE_KEY.UserDetails);
    if (userDetails) {
      const userDataFromStorage = JSON.parse(userDetails);
      setUserData((prevUserData) => ({
        ...prevUserData,
        role: userDataFromStorage,
      }));
    }
  }, [setUserData]);
  return (
      <Box>
          {!isLoginPage && <Header onClickDrawerMenu={ onClickDrawerMenu } />}
          <div style={{padding: '15px'}}>{ children }</div>
      </Box>
  )
}

export default AppLayout;
