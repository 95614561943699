import React from 'react';
import {Box, Button, Card, Grid, Stack, TextField, Typography} from '@mui/material';
import colobten from "../../assets/images/wims.png";
import loginBg from "../../assets/images/Background.png";
import './Login.css';
import * as url from "url";
import {theme} from "../../theme";

interface LoginLayoutProps {
    userName: string;
    password: string;
    onChangeUserName: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangePassword: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClickLogin: () => void;
}

const LoginLayout = ({ userName, password, onChangePassword, onChangeUserName, onClickLogin }: LoginLayoutProps) => {
  return (
      <Grid container>
          <Grid item xs={6} sm={6}>
              <Box sx={{ display: 'flex-end',
                  justifyContent: 'center', alignItems: 'center', gap: '20px', flexDirection: 'column'}}>
                  <img style={{height:"100vh"}} src={loginBg} alt="Colobten" />
              </Box>
          </Grid>
          <Grid item xs={6} sm={6} style={{display: 'flex-end'}}>
              <Card style={{
                  width: 600,
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 'auto',
                  boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.9)',
                  borderRadius: 10
              }}>
                  <Grid container style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      // backgroundColor: 'lightblue',
                      display: 'flex',
                      margin: 'center'
                  }}>
                      <Grid item xs={6} sm={6}>
                          <Box sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              flexDirection: 'column',
                              minHeight: '100Vh'
                          }}>
                              <Button sx={{ width: 200 }} style={{
                                  backgroundColor: "#C9D2CD",
                                  color: 'darkblue',
                                  fontWeight: 600,
                                  fontSize: 25,
                                  margin: '30px'
                              }} >CRRC Login</Button>
                              <TextField sx={{ width: 500, minHeight: 50, color: "black" }} placeholder="User Name" value={userName} onChange={onChangeUserName} />
                              <TextField sx={{ width: 500, minHeight: 50, color: "black" }} type="password" placeholder="Password" value={password} onChange={onChangePassword} />

                              {/* Move the Forgot Password inside the same Stack as the input fields */}
                              <Stack direction="row" justifyContent="flex-end" style={{ width: '165%' ,fontWeight: 600}}>
                                  <span style={{ flex: 1 }}></span> {/* This will create space between input fields and Forgot Password */}
                                  <span>Forgot Password</span>
                              </Stack>

                              <Stack direction="row" style={{ justifyContent: "space-between" }}>
                                  <Button sx={{ width: 200, fontSize: 14 }} style={{ backgroundColor: "green", margin: '50px' }}>New User</Button>
                                  <Button sx={{ width: 200, fontSize: 14 }} style={{ backgroundColor: "green", margin: '50px' }} onClick={onClickLogin}>Login</Button>
                              </Stack>
                          </Box>
                      </Grid>
                  </Grid>
              </Card>
          </Grid>
      </Grid>





  )
}

export default LoginLayout;
