import { Box } from "@mui/material";
import React, { useEffect } from "react";
import Table from "../../components/Table";
import { useUser } from "../../hooks/useUser";
import Loader from "../../components/Loader/Loader";

const ApprovedTickets = () => {
  const { getApprovalTicketList, approvalList, useUserLoading } = useUser();

  useEffect(() => {
    getApprovalTicketList();
  }, []);

  return (
    <Box>
      {useUserLoading ? (
        <Loader></Loader>
      ) : (
        <Box>
          <h1>Approval Ticket List</h1>
          <Table
            listData={approvalList}
            hyperlinkPropertyName={"oidPkFld"}
            entity={"approvalTickets"}
          ></Table>
          <Box></Box>
        </Box>
      )}
    </Box>
  );
};

export default ApprovedTickets;
