export const userEndpoint = {
    ALL_USER: '/api/user/all',
    USER_BY_ID: '/api/user/',
    ALL_WAREHOUSE: '/api/wareHouse/all',
    WAREHOUSE_BY_ID: '/api/wareHouse/',
    ALL_DEVICE: '/api/device/all',
    DEVICE_BY_ID: '/api/device/',
    LOGIN: '/auth/login',
    SIGN_UP: '/api/user/signup',
    WAREHOUSE_CREATE: '/api/wareHouse/create',
    DEVICE_CREATE: '/api/device/create',
    APPROVED_LIST: '/api/opsTicket/approvedList',
    MYTICKETS_LIST:'/api/opsTicket/myTickets',
    TICKETS_CREATE: '/api/opsTicket/create',
    UPDATE_USER_MAPPING: '/api/user/association',
    UPDATE_DEVICE_MAPPING: '/api/device/association',
    ALL_TICKETS: '/api/opsTicket/all',
    TICKET_BY_ID: '/api/opsTicket/',
    COMPANY_DROPDOWN: '/api/company/all',
    BRANCH_DROPDOWN: '/api/branch/all',
    USER_TYPE_DROPDOWN_BY_TYPE_ID:'/api/reference/',
    DEVICE_DROPDOWN_BY_TYPE_ID: '/api/reference/',
    SET_ACTIVATION: '/api/user/activate',
    CHANGE_PASSWORD: '/api/user/changePassword',
    STATION_AUDIT: '/api/auditLog/wareHouse/',
    RELAY_ROOM_STATUS: '/api/opsTicket/relayRoom',
}
