import React from "react";
import { Box, Card, Stack, Typography } from "@mui/material";

interface RelayRoomStatusProps {
  messageFld: string;
}

const RelayRoomStatusComponent = ({ messageFld }: RelayRoomStatusProps) => {
  return (
    <Box>
      <Card sx={{ width: "250px", cursor: "pointer", marginRight: "20px" }}>
        <Box
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Stack direction="row" style={{ justifyContent: "space-between" }}>
            <Typography
              sx={{
                color: "rgb(0, 101, 117)",
                fontWeight: "700",
                fontSize: "16",
                borderRadius: "25",
              }}
            >
              {messageFld}
            </Typography>
          </Stack>
        </Box>
      </Card>
    </Box>
  );
};

export default RelayRoomStatusComponent;
