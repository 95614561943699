import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {useUser} from "../../hooks/useUser";
import DetailView from "../../components/DetailView";
import CustomizedSwitches from "../../components/ToggleButton/ToggleButton";
import CustomPopup from "../../components/CustomPopup";
import Loader from "../../components/Loader/Loader";

const UserDetails = () => {
    const location = useLocation();
    const {getUserById, userDetail, updateUserDetails, useUserLoading}  = useUser();
    const userDetails: any = location?.state?.data || {};
    const navigate = useNavigate();
    const [visibility,  setVisibility] = useState<boolean>(false);
    const [isActive,  setIsActive] = useState<boolean | null>(null);
    const {activationType, setActivation} = useUser();


    const popupCloseHandler = () => {
        setVisibility(false);
    };

    useEffect(() => {
        if(userDetails && userDetails.data && userDetails.data.oidPkFld != null) {
            getUserById( userDetails.data.oidPkFld);
        } else {
            navigate('/', { replace: true });
        }
    }, []);

    useEffect(() => {
        console.log(userDetails);
        console.log(userDetail)
        if (userDetail) {
            console.log(userDetail.isActiveFld === "Y");
            setIsActive(userDetail.isActiveFld === "Y");
        }
        else {
        }
    }, [userDetail])

    const onOkClick =  () => {
        console.log(userDetails)
        setActivation(  isActive, userDetails.data.oidPkFld)
        setVisibility(false);
    }


    const onChangePasswordButton = async () => {
            navigate('/changePassword/'+ userDetail.oidPkFld);
    }

    return (
    <div> {
        useUserLoading ? <Loader></Loader> : <div>
            <div style={{
                display: 'flex', // Set display to flex to arrange children in a row
                alignItems: 'center', // Align items vertically in the center
                padding: '10px',
                flexDirection: 'row'
            }}>
                {userDetail ? (
                    <CustomizedSwitches
                        checked={isActive ?? false}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setIsActive(event.target.checked);
                            setVisibility(true);
                        }}
                    />
                ) : (
                    <div />
                )}
                <CustomPopup
                    onClose={popupCloseHandler}
                    show={visibility}
                    title="Are You Sure You Want to Activate"
                    onClick={onOkClick}
                >
                    <> </>
                </CustomPopup>
                <button
                    style={{
                        backgroundColor: '#007bff',
                        color: '#fff',
                        padding: '10px 20px',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer'
                    }} onClick={onChangePasswordButton}
                >
                    Change Password
                </button>
            </div>

            <div>
                <DetailView detailData={userDetail} entity={'user'}
                            enabledEdit={true}
                            onSave={(data:any) =>  {
                                updateUserDetails(data)
                            }}/>
            </div>

        </div>
    }


    </div>
  )
}

export default UserDetails;


