import React, {useEffect} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {useUser} from "../../hooks/useUser";
import DetailView from "../../components/DetailView";
import Loader from "../../components/Loader/Loader";

const DeviceDetails = () => {
    const location = useLocation();
    const {getDeviceById, updateDeviceDetails, useUserLoading} = useUser();
    const DeviceDetails: any = location?.state?.data || {};
    const navigate = useNavigate();

    useEffect(() => {
        if(DeviceDetails && DeviceDetails.data && DeviceDetails.data.oidPkFld != null) {
            getDeviceById( DeviceDetails.data.oidPkFld);
        } else {
            navigate('/', { replace: true });
        }
    }, []);

    return (
    <div> {useUserLoading ? <Loader></Loader> : <div>
        <div>
            <DetailView canAdd={ true} detailData={DeviceDetails} entity={'device'}  enabledEdit={true}  onSave=
                {(data:any) =>  {
                    updateDeviceDetails(data)
                }}
            />
        </div>
    </div>}
    </div>
  )
}


export default DeviceDetails;


